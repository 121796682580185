import React from 'react';
import Grid from '@mui/material/Grid';
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import TotalChartBase from "@components/cores/TotalChartBase";
import TotalTable from "@components/cores/TotalTable";
import DmmSelect from "@components/cores/DmmSelect";
import TotalModule from "@modules/TotalModule";
import Genres1Module from "@modules/Genres1Module";
import TargetsModule from "@modules/TargetsModule";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

let opt = TotalModule.getMonthlyDefaultOption();

export default function TotalMonthlyPage() {
  const classes = useStyles();

  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([]);
  const [ totalCol, setTotalCol ] = React.useState(opt.totalCol);
  const [ genre1, setGenre1 ] = React.useState(opt.genre1);
  const [ target, setTarget ] = React.useState(opt.target);
  const [ ai, setAI ] = React.useState(opt.ai);

  const loadRows = () => {
    setLoading(true);

    TotalModule.getMonthlyData(opt, (res) => {
      setLoading(false);
      if (res.err) {
        alert('DB Error.');
        return;
      }
      setRows(res.rows);
    })
  };

  const handleChangeCol = (val) => {
    setTotalCol(val);
    opt.totalCol = val;
    loadRows();
  };
  const handleChangeGenre = (val) => {
    setGenre1(val);
    opt.genre1 = val;
    loadRows();
  };
  const handleChangeTarget = (val) => {
    setTarget(val);
    opt.target = val;
    loadRows();
  };
  const handleChangeAI = (val) => {
    setAI(val);
    opt.ai = val;
    loadRows();
  };

  React.useEffect(loadRows, []);

  const handlePrintCsv = () => {
    TotalModule.getMonthlyCsv({
      totalCol: totalCol,
      genre1: genre1,
      target: target,
      ai: ai
    });
  };

  const content = (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={12}>
        <Paper className={classes.paper} style={{ height: 600 }} >
          <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Title>
            MONTHLY
            <Button
              variant="contained"
              color="secondary"
              style={{ float: "right" }}
              onClick={handlePrintCsv}
            >
              CSV出力
            </Button>
          </Title>
          <div>
            <DmmSelect
              label="項目"
              labelId="total-col"
              val={totalCol}
              onChange={handleChangeCol}
              items={TotalModule.COL_ITEMS}
            />
            <DmmSelect
              label="ジャンル(大)"
              labelId="genre"
              val={genre1}
              onChange={handleChangeGenre}
              items={Genres1Module.getSelectList()}
            />
            <DmmSelect
              label="ターゲット層"
              labelId="target"
              val={target}
              onChange={handleChangeTarget}
              items={TargetsModule.getSelectList()}
            />
            <DmmSelect
              label="AI"
              labelId="ai"
              val={ai}
              onChange={handleChangeAI}
              items={TotalModule.AI_ITEMS}
            />
          </div>
          <TotalChartBase rows={rows} />
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }} >
          <TotalTable rows={rows} />
        </Paper>
      </Grid>
    </Grid>
  );
  return <PageTemplate content={content} />;
}
