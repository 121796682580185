export const genres1 = [
  {
    "genre1": 1,
    "genre_name": "コミック",
    "sort_no": 1
  },
  {
    "genre1": 2,
    "genre_name": "CG",
    "sort_no": 2
  },
  {
    "genre1": 3,
    "genre_name": "ゲーム",
    "sort_no": 3
  },
  {
    "genre1": 4,
    "genre_name": "ボイス",
    "sort_no": 4
  },
  {
    "genre1": 5,
    "genre_name": "動画",
    "sort_no": 5
  }
]
