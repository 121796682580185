import React from 'react';
import Grid from '@mui/material/Grid';

import DataManager from "@modules/DataManager";
import Report from "@components/cores/Report";
import PageTemplate from "@components/cores/PageTemplate";
import TotalDailyReport from "@components/cores/TotalDailyReport";
import TotalReport from "@components/cores/TotalReport";
import ContentComparisonForm from "../cores/ContentComparisonForm";

export default function DashboardPage() {
  DataManager.isLogin();

  const content = (
    <Grid container spacing={3}>
      <Report link="/totaldaily" title="累計 [DAILY - 売上]" content={
        <TotalDailyReport totalcol="profit" />
      } />
      <Report link="/totaldaily?totalcol=increase" title="累計 [DAILY - 新規増加]" content={
        <TotalDailyReport totalcol="increase" />
      } />
      <Report title="累計コンテンツ数" content={
        <TotalReport />
      } />
      <Report title="個別比較" content={
        <ContentComparisonForm />
      } />
    </Grid>
  );

  return <PageTemplate content={content} />;
}
