import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';

import AlertDialogManager from '@modules/AlertDialogManager';
import DialogManager from '@modules/DialogManager';
import TotalModule from "@modules/TotalModule";
import DmmSelect from "./DmmSelect";
import Genres1Module from "@modules/Genres1Module";
import TargetsModule from "@modules/TargetsModule";
import BatchModule from "@modules/BatchModule";


export default function BatchTimeAxisDialog() {
  const [open, setOpen] = React.useState(false);
  const [cols, setCols] = React.useState({});

  let opt = TotalModule.getDailyDefaultOption();
  const [ site_id, setSiteID ] = React.useState('dlsite');
  const [ batch_name, setBatchName ] = React.useState('');
  const [ dateKeyFrom, setDateKeyFrom ] = React.useState(opt.dateKeyFrom);
  const [ dateKeyEnd, setDateKeyEnd ] = React.useState(opt.dateKeyEnd);
  const [ genre1, setGenre1 ] = React.useState(opt.genre1);
  const [ target, setTarget ] = React.useState(opt.target);
  const [ freshness, setFreshness ] = React.useState(opt.freshness);
  const [ ai, setAI ] = React.useState(0);

  DialogManager.init(setOpen, () => {});
  const handleChecked = (e, k) => {
    let c = cols;
    if (e.target.checked) {
      c[k] = true;
    } else {
      delete c[k];
    }
    setCols(c);
  };
  const handleChangeSiteID = (val) => {
    setSiteID(val);
  };
  const handleChangeBatchName = (e) => {
    setBatchName(e.target.value);
  };
  const handleChangeDateKeyFrom = (e) => {
    setDateKeyFrom(e.target.value);
  };
  const handleChangeDateKeyEnd = (e) => {
    setDateKeyEnd(e.target.value);
  };
  const handleChangeGenre = (val) => {
    setGenre1(val);
  };
  const handleChangeTarget = (val) => {
    setTarget(val);
  };
  const handleChangeFreshness = (val) => {
    setFreshness(val);
  };
  const handleChangeAI = (val) => {
    setAI(val);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (typeof batch_name === 'string' && batch_name.length === 0) {
      AlertDialogManager.show('ERROR', '「処理名」を入力してください。');
      return;
    }
    if (typeof cols !== 'object' || Object.keys(cols).length === 0) {
      AlertDialogManager.show('ERROR', '「出力項目」を１つ以上選択してください。');
      return;
    }
    BatchModule.insertTimeAxis({
      cols: Object.keys(cols),
      site_id: site_id,
      batch_name: batch_name,
      datekey_from: dateKeyFrom,
      datekey_end: dateKeyEnd,
      genre1: genre1,
      target: target,
      freshness: freshness,
      ai: ai
    }, (res) => {
      if (res.data.err) {
        AlertDialogManager.show('ERROR', 'DB Error.');
        return 0;
      } else {
        DialogManager.commitFunction({
          id: res.data.batch_id,
          created: new Date().toLocaleDateString('sv-SE'),
          batch_name: batch_name,
          status: 0,
          started: null,
          completed: null
        });
        setOpen(false);
      }
    });
    //
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">バッチ処理登録</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            '& > :not(style)': { m: 1, width: '60ch' },
          }}
        >
          <FormLabel sx={{ marginTop: 2 }}>基本情報</FormLabel>
          <DmmSelect
            label="SITE"
            labelId="site_id"
            val={site_id}
            onChange={handleChangeSiteID}
            items={[
              { val: 'dlsite', label: 'DLsite' },
              { val: 'fanza', label: 'FANZA' }
            ]}
          />
          <TextField
            id="batch_name"
            label="処理名"
            defaultValue={batch_name}
            value={batch_name}
            onChange={handleChangeBatchName}
            sx={{ margin: 1 }}
          />

          <Divider sx={{ marginTop: 4 }} />
          <FormControl component="fieldset">
            <FormLabel component="legend">出力項目</FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel control={
                <Checkbox checked={cols['profit']} onChange={(e) => {
                  handleChecked(e, 'profit');
                }} />
              } label="売上金額" labelPlacement="end" />
              <FormControlLabel control={
                <Checkbox checked={cols['sales_diff']} onChange={(e) => {
                  handleChecked(e, 'sales_diff');
                }} />
              } label="販売数" labelPlacement="end" />
              <FormControlLabel control={
                <Checkbox checked={cols['fans_diff']} onChange={(e) => {
                  handleChecked(e, 'fans_diff');
                }} />
              } label="お気に入り登録数" labelPlacement="end" />
            </FormGroup>

            <Divider sx={{ marginTop: 4 }} />
            <FormLabel sx={{ marginTop: 2 }}>フィルター</FormLabel>
            <TextField
              id="datekeyfrom"
              label="日付(FROM)"
              type="date"
              defaultValue={dateKeyFrom}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeDateKeyFrom}
              sx={{ margin: 1 }}
            />
            <TextField
              id="datekeyend"
              label="日付(TO)"
              type="date"
              defaultValue={dateKeyEnd}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeDateKeyEnd}
              sx={{ margin: 1 }}
            />
            <DmmSelect
              label="ジャンル(大)"
              labelId="genre"
              val={genre1}
              onChange={handleChangeGenre}
              items={Genres1Module.getSelectList()}
            />
            <DmmSelect
              label="ターゲット層"
              labelId="target"
              val={target}
              onChange={handleChangeTarget}
              items={TargetsModule.getSelectList()}
            />
            <DmmSelect
              label="作品鮮度"
              labelId="freshness"
              val={freshness}
              onChange={handleChangeFreshness}
              items={TotalModule.FRESHNESS_ITEMS}
            />
            <DmmSelect
              label="AI"
              labelId="ai"
              val={ai}
              onChange={handleChangeAI}
              items={[
                { val: 0, label: 'すべて' },
                { val: 1, label: 'AI作品' },
                { val: 2, label: 'AI一部利用' },
                { val: 3, label: 'AI作品 + AI一部利用' },
              ]}
            />

          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}