/**
 * ダッシュボード用のパーツ
 */

import React from 'react';
import TotalChartBase from "@components/cores/TotalChartBase";
import TotalModule from "@modules/TotalModule";

export default function TotalDailyReport(props) {
  const [ rows, setRows ] = React.useState([]);

  const loadRows = () => {
    const opt = TotalModule.getDailyDefaultOption();
    opt.totalCol = props.totalcol;
    TotalModule.getDailyData(opt, (res) => {
      if (res.err) {
        return;
      }
      setRows(res.rows);
    })
  };
  React.useEffect(loadRows, [ props.totalcol ]);

  return <TotalChartBase rows={rows} />;
}
