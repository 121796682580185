import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export default function TotalTable(props) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell align="right">FANZA</TableCell>
          <TableCell align="right">DLsite</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row, idx) => (
          <TableRow key={'row-'+idx}>
            <TableCell>{row.date}</TableCell>
            <TableCell align="right">{row.FANZA.toLocaleString()}</TableCell>
            <TableCell align="right">{row.DLsite.toLocaleString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
