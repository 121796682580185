import BaseApiModule from "./BaseApiModule";

export default class RankModule {
  static TERMS() {
    return [
      { val: 'DAILY', label: 'DAILY' },
      { val: 'WEEKLY', label: 'WEEKLY' },
      { val: 'MONTHLY', label: 'MONTHLY' },
      { val: 'YEAR', label: 'YEAR' },
      { val: 'TOTAL', label: 'TOTAL' }
    ];
  }
  static SORTS() {
    return [
      { val: 'FAVORITE', label: 'お気に入り順' },
      { val: 'SALES', label: '販売数順' }
    ];
  }
  static GENRES1() {
    return [
      { val: 'TOTAL', label: '総合' },
      { val: 'COMIC', label: 'コミック' },
      { val: 'CG', label: 'CG' },
      { val: 'GAME', label: 'ゲーム' },
      { val: 'VOICE', label: 'ボイス' },
      { val: 'MOVIE', label: '動画' }
    ];
  }
  static TARGETS() {
    return [
      { val: 1, label: '全年齢' },
      { val: 2, label: '同人' },
      { val: 3, label: 'TL/乙女向け' },
      { val: 4, label: 'BL' }
    ];
  }

  static getRank(site_id, term, sort, genre1, target, date_key, fnc) {
    BaseApiModule.post(
      '/rank/get_' + site_id,
      {
        term: term,
        sort: sort,
        genre1: genre1,
        target: target,
        date_key: date_key
      },
      (res) => { fnc(res.data); }
    );
  }

  static getCsv(site_id, term, sort, genre1, target, date_key) {
    BaseApiModule.download(
      '/rank/get_' + site_id + '_csv',
      {
        term: term,
        sort: sort,
        genre1: genre1,
        target: target,
        date_key: date_key
      },
      'rank.csv'
    );
  }
}
