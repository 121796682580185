import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ConfirmDialogManager from '@modules/ConfirmDialogManager';


export default function ConfirmDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [text, setText] = React.useState('');
  const [ok_text, setOKText] = React.useState('OK');
  const [cancel_text, setCancelText] = React.useState('Cancel');
  ConfirmDialogManager.init(setOpen, setTitle, setText, setOKText, setCancelText);

  const handleOK = () => {
    ConfirmDialogManager.returnFunction(1);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
    ConfirmDialogManager.returnFunction(0);
  };

  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {text}
      </DialogContent>
      <DialogActions sx={{ textAlign: 'center' }}>
        <Button onClick={handleCancel} color="primary">
          {cancel_text}
        </Button>
        <Button onClick={handleOK} color="primary">
          {ok_text}
        </Button>
      </DialogActions>
    </Dialog>
  );
}