import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LaunchIcon from '@mui/icons-material/Launch';
import {Box, Tooltip} from "@mui/material";

import DialogManager from '@modules/DialogManager';
import CircleModule from "@modules/CircleModule";

let target_row = {
  id: 0,
  fanza_circle_id: "",
  dlsite_circle_id: "",
  circle_name: ""
};

const CircleDetail = (props) => {
  return (
    <Grid container>
      <Grid item xs={1.5}>
        <Link
          href={props.link}
          target="_blank"
        >
          <Tooltip title="サークル詳細ページに遷移">
            <LaunchIcon sx={{ fontSize: 50 }} />
          </Tooltip>
        </Link>
      </Grid>
      <Grid item xs={10}>
        <Typography>
          {props.site}サークルID: {props.circle_id}<br />
          {props.site}サークル名: {props.circle_name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default function CircleNameFormDialog(props) {
  const theme = createTheme();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({
    id: 0,
    fanza_circle_id: "",
    dlsite_circle_id: "",
    circle_name: ""
  });
  const [circles, setCircles] = React.useState([]);
  const [circleId, setCircleId] = React.useState('');
  const [circleName, setCircleName] = React.useState('');
  DialogManager.init(setOpen, (c) => {
    setRow(c);
    setCircles([]);
    setCircleId('');
    setCircleName('');
  });

  const handleSearchCircle = () => {
    if ( ! row.id) return;
    let target_site = '';
    if (row.fanza_circle_id) {
      target_site = 'dlsite';
    } else if (row.dlsite_circle_id) {
      target_site = 'fanza';
    } else {
      return;
    }

    CircleModule.getSearchedCircles(row.id, target_site, (res) => {
      if ( ! Array.isArray(res)) {
        alert('DB Error !');
        return;
      }
      if (res.length === 0) {
        alert('似たサークル名が見つかりませんでした。');
        return;
      }
      setCircles(res);
    });
  };

  const handleCircleSelected = (r) => {
    target_row = r;
    setCircleId(r.circle_id);
    setCircleName(r.circle_name);
  };

  const handleCircleIdChange = (e) => {
    let cid = e.target.value;
    setCircleId(cid);
    let target_site = '';
    if (row.fanza_circle_id) {
      target_site = 'dlsite';
    } else if (row.dlsite_circle_id) {
      target_site = 'fanza';
    } else {
      return;
    }
    target_row = CircleModule.getRelationCircle(cid, target_site);
    if (target_row) {
      setCircleName(target_row.circle_name);
    } else {
      setCircleName('');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (!target_row) {
      alert('紐付けるサークルが選択されていません。');
      return;
    }
    CircleModule.saveRelation(row.id, target_row.id, (res) => {
      if (res.err) {
        alert('DB Error.');
        return;
      }
      setOpen(false);
      DialogManager.commitFunction(row, target_row);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">サークル紐付け</DialogTitle>
      <DialogContent>
        <Paper
          elevation={0}
          variant="outlined"
          style={{ marin: theme.spacing(3), padding: theme.spacing(2) }}
        >
          {row.fanza_circle_id &&
            <CircleDetail
              site="FANZA"
              link={'https://www.dmm.co.jp/dc/doujin/-/list/=/article=maker/id='+row.fanza_circle_id+'/'}
              circle_id={row.fanza_circle_id}
              circle_name={row.circle_name}
            />
          }
          {row.dlsite_circle_id &&
            <CircleDetail
              site="DLsite"
              link={'https://www.dlsite.com/maniax/circle/profile/=/maker_id/'+row.dlsite_circle_id+'.html'}
              circle_id={row.dlsite_circle_id}
              circle_name={row.circle_name}
            />
          }
        </Paper>
        <div align="center">
          <Button
            color="primary"
            style={{ margin: theme.spacing(1) }}
            startIcon={<AssignmentReturnedIcon />}
            onClick={handleSearchCircle}
            variant="outlined"
          >
            似たサークル名を検索
          </Button>
        </div>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{ pt: 1, pb: 1 }}
        >
          {circles.map((r) =>
            <Grid container key={'list-' + r.id}>
              <Grid item xs={0.8} sx={{ mt: 0.8, ml: 2 }}>
                <Tooltip title="サークル詳細ページに遷移" placement="left-start">
                  <Link href={r.link} target="_blank">
                    <LaunchIcon />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <Button
                  fullWidth
                  sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                  onClick={() => { handleCircleSelected(r); }}
                >
                  [{r.circle_id}] {r.circle_name}
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>

        <Typography sx={{ marginTop: theme.spacing(3) }}>
          紐付けるサークルを指定
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <div>
            <FormControl variant="standard">
              <InputLabel htmlFor="relation-circle-id" sx={{ marginLeft: 1.7 }}>サークルID</InputLabel>
              <OutlinedInput
                id="relation-circle-id"
                value={circleId}
                onChange={handleCircleIdChange}
                label="サークルID"
                sx={{ marginTop: 0.5 }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ ml: 1.5 }}>
              <Typography sx={{ mt: 2.3 }}>
                {circleName}
              </Typography>
            </FormControl>
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}