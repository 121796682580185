import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { AuthProvider } from "../hooks/AuthProvider";

import UserModule from "@modules/UserModule";


export const ProtectedLayout = () => {
  const outlet = useOutlet();

  if (!UserModule.isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <AuthProvider>
      {outlet}
    </AuthProvider>
  );
};
