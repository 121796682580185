import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import AlertDialogManager from '@modules/AlertDialogManager';
import ConfirmDialogManager from "../../modules/ConfirmDialogManager";
import DialogManager from '@modules/DialogManager';
import UserModule from "@modules/UserModule";

export default function UserFormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [edit_mode, setEditMode] = React.useState('UPD');
  const [user_id, setUserID] = React.useState('');
  const [user_id_label, setUserIDLabel] = React.useState('（変更できません）');
  const [user_name, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [show_password, setShowPassword] = React.useState(false);
  const [is_admin, setAdmin] = React.useState(false);

  DialogManager.init(setOpen, (usr) => {
    if (usr === null) {
      setEditMode('ADD');
      setUserIDLabel('');
      setUserID('');
      setUserName('');
      setPassword('');
      setAdmin(false);
    } else {
      setEditMode('UPD');
      setUserIDLabel('（変更できません）');
      setUserID(usr.user_id);
      setUserName(usr.user_name);
      setPassword('');
      setAdmin(usr.is_admin === 1);
    }
  });

  const handleChangeUserID = (e) => {
    setUserID(e.target.value);
  };

  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleCheckAdmin = (e) => {
    setAdmin(e.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    UserModule.save({
      user_id: user_id,
      user_name: user_name,
      password: password,
      is_admin: is_admin
    }, (res) => {
      console.log(res);
      if (res.err === 0) {
        setOpen(false);
        DialogManager.commitFunction({
          edit_mode: edit_mode,
          user_id: user_id,
          user_name: user_name,
          is_admin: is_admin
        });
      } else if (res.err === 1) {
        AlertDialogManager.show('IDを入力してください。');
      } else if (res.err === 2) {
        AlertDialogManager.show('名前を入力してください。');
      } else if (res.err === 3) {
        AlertDialogManager.show('パスワードとして設定できる文字数は、128バイトまでです。');
      } else if (res.err === 4) {
        AlertDialogManager.show('予期しないエラーが発生しました。');
      } else {
        AlertDialogManager.show('予期しないエラーが発生しました。');
      }
    });
  };

  const handleDelete = () => {
    ConfirmDialogManager.show(
      'DELETE: ' + user_name,
      '本当に削除しても良いですか？',
      'DELETE',
      'CANCEL',
      (res) => {
        if (res === 0) return;
        UserModule.delete(user_id, (res) => {
          if (res.err > 0) {
            AlertDialogManager.show('予期しないエラーが発生しました。');
            return;
          }
          setOpen(false);
          DialogManager.commitFunction({
            edit_mode: 'DEL',
            user_id: user_id
          });
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">ユーザー編集</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
        >
          <div>
            <TextField
              id="user_id"
              label={"ログインID" + user_id_label}
              value={user_id}
              InputProps={{ readOnly: (edit_mode === 'UPD') }}
              onChange={handleChangeUserID}
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <TextField
              id="user_name"
              label="名前"
              variant="outlined"
              value={user_name}
              onChange={handleChangeUserName}
            />
          </div>

          <FormControl sx={{ m: 1, width: '320px' }} variant="outlined">
            <InputLabel htmlFor="password" sx={{ maxWidth: '100%' }}>Password（変更時のみ入力） </InputLabel>
            <OutlinedInput
              id="password"
              type={show_password ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {show_password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              sx={{ width: '300px' }}
              value={password}
              onChange={handleChangePassword}
            />
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={is_admin}
                onChange={handleCheckAdmin}
                inputProps={{ 'aria-label': 'controlled' }}
              />}
              label="管理権限（ユーザー管理）"
              sx={{ width: 250 }}
            />
          </FormGroup>
        </Box>
      </DialogContent>

      <DialogActions>
        {edit_mode === 'UPD'
          && <Button color="secondary" sx={{ marginRight: 'auto' }} onClick={handleDelete}>Delete</Button>
        }
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}