import React from 'react';

import BatchTimeAxisDialog from "@components/cores/BatchTimeAxisDialog";
import BatchPageTemplate from "./BatchPageTemplate";

export default function BatchTimeAxisPage() {
  return <BatchPageTemplate
    batch_type="time_axis"
    dialog={<BatchTimeAxisDialog />}
  />;
}
