
export default class AlertDialogManager {
  static _open_setter = () => {};
  static _title_setter = () => {};
  static _text_setter = () => {};

  /**
   * @param open_setter ダイアログの表示切り替え用ファンクション
   * @param title_setter タイトル設定ファンクション
   * @param text_setter テキスト設定ファンクション
   */
  static init(open_setter, title_setter, text_setter) {
    this._open_setter = open_setter;
    this._title_setter = title_setter;
    this._text_setter = text_setter;
  }

  static show(title, text) {
    this._open_setter(true);
    this._title_setter(title);
    this._text_setter(text);
  }
}
