import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";

import ContentModule from "@modules/ContentModule";
import DialogManager from "@modules/DialogManager";
import TotalChartBase from "@components/cores/TotalChartBase";
import DmmSelect from "./DmmSelect";
import TextField from "@mui/material/TextField";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
});

const COMPARISON_TYPE = [
  { val: 'rank', label: 'RANK' },
  { val: 'net_sales', label: '売上金額' },
  { val: 'sales', label: '販売数' },
  { val: 'price', label: '販売価格' }
];
let comparison_data = {
  rank: [],
  net_sales: [],
  sales: [],
  price: []
};

export default function ContentComparisonDialog() {
  const classes = useStyles();

  const [ props, setProps ] = React.useState({
    cid: 0,
    title: ''
  });
  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([]);
  const [ open, setOpen ] = React.useState(false);
  const [ opt, setOptions ] = React.useState(
    ContentModule.getTrendsDefaultOption()
  );

  const loadRows = (cid, o) => {
    setLoading(true);
    ContentModule.getTrends(
      cid,
      o.datekey_from,
      o.datekey_end,
      (res) => {
        comparison_data = res.data;
        setRows(comparison_data[o.comparison_type]);
        setLoading(false);
      }
    );
  };

  const handleComparisonType = (t) => {
    setOptions({
      comparison_type: t,
      datekey_from: opt.datekey_from,
      datekey_end: opt.datekey_end
    });
    setRows(comparison_data[t]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDateKeyFrom = (e) => {
    let o = {
      comparison_type: opt.comparison_type,
      datekey_from: e.target.value,
      datekey_end: opt.datekey_end
    };
    setOptions(o);
    loadRows(props.cid, o);
  };

  const handleChangeDateKeyEnd = (e) => {
    let o = {
      comparison_type: opt.comparison_type,
      datekey_from: opt.datekey_from,
      datekey_end: e.target.value
    };
    setOptions(o);
    loadRows(props.cid, o);
  };

  DialogManager.init(setOpen, (p) => {
    setProps(p);
    loadRows(p.cid, opt);
  });

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={false}>
      <DialogTitle id="form-dialog-title">「{props.title}」の比較</DialogTitle>
      <DialogContent style={{ width: '100%' }}>
        <DmmSelect
          label="比較種別"
          labelId="comparison_type"
          val={opt.comparison_type}
          items={COMPARISON_TYPE}
          onChange={handleComparisonType}
        />
        <TextField
          id="datekeyfrom"
          label="日付(FROM)"
          type="date"
          defaultValue={opt.datekey_from}
          style={{ margin: theme.spacing(1), minWidth: 200 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleChangeDateKeyFrom}
        />
        <TextField
          id="datekeyend"
          label="日付(TO)"
          type="date"
          defaultValue={opt.datekey_end}
          style={{ margin: theme.spacing(1), minWidth: 200 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleChangeDateKeyEnd}
        />
        <Paper className={classes.paper} style={{ height: 600 }}>
          <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <TotalChartBase rows={rows} />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
