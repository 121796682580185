import React from 'react';
import clsx from "clsx";
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles"
import Grid from '@mui/material/Grid';
import {DataGrid} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import CircleModule from "@modules/CircleModule";
import CircleNameFormDialog from "@components/cores/CircleNameFormDialog";
import DialogManager from "@modules/DialogManager";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '85vh',
  },
});

const cols = [
  { field: 'fanza_circle_id', headerName: 'サークルID', width: 150 },
  { field: 'dlsite_circle_id', headerName: 'DLsiteサークルID', width: 150 },
  { field: 'circle_name', headerName: 'サークル名', width: 400 }
];

export default function SettingCirclePage() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([]);
  const [ filter, setFilter ] = React.useState('');
  React.useEffect(() => {
    CircleModule.getRelations(true, (data) => {
      setLoading(false);
      setRows(data);
    });
  }, []);

  const updateRow = (src, target) => {
    let newRows = CircleModule.relations.filter((r) => {
      return (r.id !== src.id) && (r.id !== target.id);
    });
    setRows(newRows);
    CircleModule.setRelations(newRows);
    setFilter('');
  };

  const handleChangeFilter = (e) => {
    let txt = e.target.value;
    setFilter(txt);
    txt = txt.toLowerCase();
    setRows(CircleModule.relations.filter((r) => {
      if (String(r.id).toLowerCase().indexOf(txt) >= 0) return true;
      if (r.circle_name.toLowerCase().indexOf(txt) >= 0) return true;
      return false;
    }));
  };

  const content = (
    <>
      <CircleNameFormDialog />
      <Grid container spacing={3}>

        <Grid item xs={12} md={8} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Title>
              サークル紐付け表
              <TextField
                type="search"
                size="small"
                label="Filter"
                variant="outlined"
                value={filter}
                onChange={handleChangeFilter}
                sx={{ float: 'right' }}
              />
            </Title>
            <DataGrid
              style={{ height: '100%' }}
              columns={cols}
              rows={rows}
              onRowClick={(params) => {
                DialogManager.show(params.row, updateRow);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
  return <PageTemplate content={content} />;
}
