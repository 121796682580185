import BaseApiModule from "./BaseApiModule";

export default class BatchModule {
  static STATUS_NOT_STARTED = 0;
  static STATUS_STARTED = 1;
  static STATUS_COMPLETED = 9;
  static STATUS_ERR = 99;

  static insertTimeAxis(params, fnc) {
    BaseApiModule.post('/batch/insert_time_axis', params, fnc);
  }
  static insertContents(params, fnc) {
    BaseApiModule.post('/batch/insert_contents', params, fnc);
  }
  static getRows(batch_type, fnc) {
    BaseApiModule.post('/batch/get_batches', {
      batch_type: batch_type
    }, fnc);
  }
  static getDownloadURL(batch_id, fnc) {
    BaseApiModule.post('/batch/download_url', {
      batch_id: batch_id
    }, fnc);
  }
  static delete(batch_id, fnc) {
    BaseApiModule.post('/batch/delete', {
      batch_id: batch_id
    }, fnc);
  }
}
