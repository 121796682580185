import React from 'react';

import BatchContentsDialog from "../cores/BatchContentsDialog";
import BatchPageTemplate from "./BatchPageTemplate";

export default function BatchContentsPage() {
  return <BatchPageTemplate
    batch_type="contents"
    dialog={<BatchContentsDialog />}
  />;
}
