import React from 'react';

import PageTemplate from "@components/cores/PageTemplate";
import RankingForm from "@components/cores/RankingForm";
import RankModule from "@modules/RankModule";

export default function RankingFanzaPage() {
  const content = <RankingForm
    site_id="fanza"
    genres1={RankModule.GENRES1()}
  />
  return <PageTemplate content={content} />;
}
