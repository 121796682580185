import React from 'react';
import createTheme from "@mui/material/styles/createTheme";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip
} from 'recharts';

export default function TotalChartBase(props) {
  const theme = createTheme();

  return (
    <ResponsiveContainer>
      <LineChart
        data={props.rows}
        margin={{
          top: 16,
          right: 20,
          bottom:50,
          left: 34,
        }}
      >
        <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
        <YAxis stroke={theme.palette.text.secondary} />
        <Line type="monotone" dataKey="FANZA" stroke="red" />
        <Line type="monotone" dataKey="DLsite" stroke="blue" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Tooltip formatter={(val) => val.toLocaleString()} />
      </LineChart>
    </ResponsiveContainer>
  );
}
