import React from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function DmmSelect(props) {
  const theme = useTheme();
  const [ val, setVal ] = React.useState(props.val);
  const handleChange = (e) => {
    setVal(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ margin: theme.spacing(1), minWidth: 200 }}
    >
      <InputLabel id={props.labelId + '-input'}>{props.label}</InputLabel>
      <Select
        labelId={props.labelId + '-label'}
        id={props.labelId + '-select'}
        label={props.label}
        value={val}
        onChange={handleChange}
      >
        {props.items ? props.items.map((item, idx) =>
          <MenuItem
            key={props.labelId+'-item-'+idx}
            value={item.val}
          >
            {item.label}
          </MenuItem>
        ) : []}
      </Select>
    </FormControl>
  );
}
