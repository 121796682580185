import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import DashboardIcon from '@mui/icons-material/Dashboard';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import StorageIcon from '@mui/icons-material/Storage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PaletteIcon from '@mui/icons-material/Palette';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TitleIcon from '@mui/icons-material/Title';
import PeopleIcon from '@mui/icons-material/People';


import UserModule from "@modules/UserModule";


const theme = createTheme();
const useStyles = makeStyles({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

let menus = [
  { title: 'ダッシュボード', path: '/', icon: <DashboardIcon /> },
  { title: '累計データ', path: '/total', icon: <DataUsageIcon />, children: [
      { title: 'DAILY', path: '/totaldaily', icon: <TimelineIcon /> },
      { title: 'MONTHLY', path: '/totalmonthly', icon: <TimelineIcon /> },
      { title: 'YEARLY', path: '/totalyearly', icon: <TimelineIcon /> },
    ]
  },
  { title: 'バッチ集計', path: '/batch', icon: <DataUsageIcon />, children: [
      { title: '時間軸', path: '/batchtimeaxis', icon: <AccessTimeIcon /> },
      { title: 'コンテンツ単位', path: '/batchcontents', icon: <MenuBookIcon /> }
    ]
  },
  { title: 'ランキング', path: '/ranking', icon: <StorageIcon />, children: [
      { title: 'FANZA', path: '/rankingfanza', icon: <BarChartIcon /> },
      { title: 'DLsite', path: '/rankingdlsite', icon: <BarChartIcon /> }
    ]
  },
  { title: '設定', path: '/setting', icon: <SettingsApplicationsIcon />, children: [
      { title: 'ジャンル(大)', path: '/settinggenre1', icon: <PaletteIcon /> },
      { title: 'ジャンル(中)', path: '/settinggenre2', icon: <AccountTreeIcon /> },
      { title: 'サークル', path: '/settingcircle', icon: <ManageAccountsIcon /> },
      { title: 'タイトル', path: '/settingtitle', icon: <TitleIcon /> }
    ]
  }
];

const isSelected = (menu) => {
  const path = window.location.pathname;
  if (menu.children) {
    return (path.indexOf(menu.path) !== -1);
  } else {
    return (path === menu.path);
  }
};

export default function DmmMenus() {
  const classes = useStyles();
  if (menus[4].children.length === 4 && UserModule.isAdmin()) {
    menus[4].children.push({
      title: 'ユーザー', path: '/settinguser', icon: <PeopleIcon />
    })
  }

  menus.forEach((menu) => {
    menu.selected = isSelected(menu);
    if (menu.children) {
      menu.children.forEach((m) => {
        m.selected = isSelected(m);
      });
    }
  });
  [ menus[1].selected, menus[1].setter ] = React.useState(menus[1].selected);
  [ menus[2].selected, menus[2].setter ] = React.useState(menus[2].selected);
  [ menus[3].selected, menus[3].setter ] = React.useState(menus[3].selected);
  [ menus[4].selected, menus[4].setter ] = React.useState(menus[4].selected);

  const handleToggle = (menu) => {
    if (!menu.setter) return;
    menu.setter(!menu.selected);
  };

  const handleMove = (path) => {
    window.location.href = path;
  }

  return (
    <List>
      {menus.map((menu, idx) => (
        <React.Fragment key={'menu-'+idx.toString()}>
          <ListItemButton
            key={'menu-list-'+idx}
            selected={menu.selected && !menu.children}
            onClick={() => {
              if (menu.children) {
                handleToggle(menu);
              } else {
                handleMove(menu.path);
              }
            }}
          >
            <ListItemIcon>
              {menu.icon}
            </ListItemIcon>
            <ListItemText primary={menu.title} />
            {menu.children && (menu.selected ?
              <ExpandLess /> :
              <ExpandMore />
              )
            }
          </ListItemButton>
          {menu.children && (
            <Collapse in={menu.selected} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ paddingLeft: 4 }}>
                {menu.children.map((m, i) => (
                  <ListItemButton
                    key={'menu-list-'+idx.toString()+'-'+i.toString()}
                    className={classes.nested}
                    selected={m.selected}
                    onClick={() => { handleMove(m.path); }}
                  >
                    <ListItemIcon>
                      {m.icon}
                    </ListItemIcon>
                    <ListItemText primary={m.title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}