import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles"
import Grid from '@mui/material/Grid';
import {DataGrid} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CircularProgress from "@mui/material/CircularProgress";

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import UserModule from "@modules/UserModule";
import UserFormDialog from "@components/cores/UserFormDialog";
import DialogManager from "@modules/DialogManager";
import IconButton from "@mui/material/IconButton";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '85vh',
  },
});

const cols = [
  {
    field: 'change_pwd',
    headerName: '',
    width: 45,
    renderCell: () => <AccountCircleIcon />
  },
  { field: 'user_id', headerName: 'ログインID', width: 150 },
  { field: 'user_name', headerName: '名前', width: 150 },
  {
    field: 'is_admin',
    headerName: '管理権限',
    renderCell: (params) => {
      if (params.row.is_admin) {
        return <CheckIcon color="success" />;
      } else {
        return <></>;
      }
    }
  }
];

export default function SettingUserPage() {
  const classes = useStyles();
  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([]);

  React.useEffect(() => {
    UserModule.getList((data) => {
      setLoading(false);
      setRows(data);
    });
  }, []);

  const saveUser = (usr) => {
    let is_adm = 0;
    if (usr.is_admin) is_adm = 1;

    if (usr.edit_mode === 'ADD') {
      let users = rows.map(r => r);
      users.push({
        id: usr.user_id,
        user_id: usr.user_id,
        user_name: usr.user_name,
        is_admin: is_adm
      });
      users.sort((a, b) => a.user_id.localeCompare(b.user_id));
      setRows(users);
    } else if (usr.edit_mode === 'UPD') {
      setRows(rows.map((row) => {
        if (row.user_id === usr.user_id) {
          return {
            id: row.id,
            user_id: row.user_id,
            user_name: usr.user_name,
            is_admin: is_adm
          };
        }
        return row;
      }));
    } else if (usr.edit_mode === 'DEL') {
      setRows(rows.filter(row => row.user_id !== usr.user_id));
    }
  };

  const content = (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={12}>
          <UserFormDialog />
          <Paper className={classes.paper}>
            <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Title>
              ユーザー管理
              <IconButton
                sx={{ float: 'right' }}
                aria-label="追加"
                onClick={() => {
                  DialogManager.show(null, saveUser);
                }}
              >
                <PersonAddIcon fontSize="large" color="primary" />
              </IconButton>
            </Title>
            <DataGrid
              style={{ height: '100%' }}
              columns={cols}
              rows={rows}
              onRowClick={(params) => {
                DialogManager.show(params.row, saveUser);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
  );
  return <PageTemplate content={content} />;
}
