import React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import TotalChartBase from "@components/cores/TotalChartBase";
import TotalTable from "@components/cores/TotalTable";
import DmmSelect from "@components/cores/DmmSelect";
import TotalModule from "@modules/TotalModule";
import Genres1Module from "@modules/Genres1Module";
import TargetsModule from "@modules/TargetsModule";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
});

let opt = TotalModule.getDailyDefaultOption();

export default function TotalDailyPage() {
  const classes = useStyles();
  const search = useLocation().search;
  if (search.indexOf('totalcol=increase') > -1) {
    opt.totalCol = 'increase';
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([]);
  const [ totalCol, setTotalCol ] = React.useState(opt.totalCol);
  const [ dateKeyFrom, setDateKeyFrom ] = React.useState(opt.dateKeyFrom);
  const [ dateKeyEnd, setDateKeyEnd ] = React.useState(opt.dateKeyEnd);
  const [ genre1, setGenre1 ] = React.useState(opt.genre1);
  const [ target, setTarget ] = React.useState(opt.target);
  const [ freshness, setFreshness ] = React.useState(opt.freshness);
  const [ ai, setAI ] = React.useState(opt.ai);

  const loadRows = () => {
    setLoading(true);

    TotalModule.getDailyData(opt, (res) => {
      setLoading(false);
      if (res.err) {
        alert('DB Error.');
        return;
      }
      setRows(res.rows);
    })
  };
  const handleChangeCol = (val) => {
    setTotalCol(val);
    opt.totalCol = val;
    loadRows();
  };
  const handleChangeDateKeyFrom = (e) => {
    setDateKeyFrom(e.target.value);
    opt.dateKeyFrom = e.target.value;
    loadRows();
  };
  const handleChangeDateKeyEnd = (e) => {
    setDateKeyEnd(e.target.value);
    opt.dateKeyEnd = e.target.value;
    loadRows();
  };
  const handleChangeGenre = (val) => {
    setGenre1(val);
    opt.genre1 = val;
    loadRows();
  };
  const handleChangeTarget = (val) => {
    setTarget(val);
    opt.target = val;
    loadRows();
  };
  const handleChangeFreshness = (val) => {
    setFreshness(val);
    opt.freshness = val;
    loadRows();
  };
  const handleChangeAI = (val) => {
    setAI(val);
    opt.ai = val;
    loadRows();
  };

  React.useEffect(loadRows, []);

  const handlePrintCsv = () => {
    TotalModule.getDailyCsv({
      totalCol: totalCol,
      dateKeyFrom: dateKeyFrom,
      dateKeyEnd: dateKeyEnd,
      genre1: genre1,
      target: target,
      freshness: freshness,
      ai: ai
    });
  };

  const content = (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={12}>
        <Paper className={classes.paper} style={{ height: 650 }}>
          <Title>
            DAILY
            <Button
              variant="contained"
              color="secondary"
              style={{ float: "right" }}
              onClick={handlePrintCsv}
            >
              CSV出力
            </Button>
          </Title>

          <div>
            <DmmSelect
              label="項目"
              labelId="total-col"
              val={totalCol}
              onChange={handleChangeCol}
              items={TotalModule.COL_ITEMS}
            />
            <TextField
              id="datekeyfrom"
              label="日付(FROM)"
              type="date"
              defaultValue={dateKeyFrom}
              style={{ margin: theme.spacing(1), minWidth: 200 }}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeDateKeyFrom}
            />
            <TextField
              id="datekeyend"
              label="日付(TO)"
              type="date"
              defaultValue={dateKeyEnd}
              style={{ margin: theme.spacing(1), minWidth: 200 }}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeDateKeyEnd}
            />
            <DmmSelect
              label="ジャンル(大)"
              labelId="genre"
              val={genre1}
              onChange={handleChangeGenre}
              items={Genres1Module.getSelectList()}
            />
            <DmmSelect
              label="ターゲット層"
              labelId="target"
              val={target}
              onChange={handleChangeTarget}
              items={TargetsModule.getSelectList()}
            />
            <DmmSelect
              label="作品鮮度"
              labelId="freshness"
              val={freshness}
              onChange={handleChangeFreshness}
              items={TotalModule.FRESHNESS_ITEMS}
            />
            <DmmSelect
              label="AI"
              labelId="ai"
              val={ai}
              onChange={handleChangeAI}
              items={TotalModule.AI_ITEMS}
            />
          </div>
          <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <TotalChartBase rows={rows} />
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <TotalTable rows={rows} />
        </Paper>
      </Grid>
    </Grid>
  );
  return <PageTemplate content={content} />;
}
