
export default class ConfirmDialogManager {
  static _open_setter = () => {};
  static _title_setter = () => {};
  static _text_setter = () => {};
  static _ok_text_setter = () => {};
  static _cancel_text_setter = () => {};
  static returnFunction = () => {};

  /**
   * @param open_setter ダイアログの表示切り替え用ファンクション
   * @param title_setter タイトル設定ファンクション
   * @param text_setter テキスト設定ファンクション
   * @param ok_text_setter
   * @param cancel_text_setter
   */
  static init(open_setter, title_setter, text_setter, ok_text_setter, cancel_text_setter) {
    this._open_setter = open_setter;
    this._title_setter = title_setter;
    this._text_setter = text_setter;
    this._ok_text_setter = ok_text_setter;
    this._cancel_text_setter = cancel_text_setter;
  }

  static show(title, text, ok_text, cancel_text, return_fnc) {
    this._open_setter(true);
    this._title_setter(title);
    this._text_setter(text);
    if (ok_text)
      this._ok_text_setter(ok_text);
    if (cancel_text)
      this._cancel_text_setter(cancel_text);
    if (return_fnc)
      this.returnFunction = return_fnc;
  }
}
