import React from 'react';
import clsx from "clsx";
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles"
import Grid from '@mui/material/Grid';
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import CircleModule from "@modules/CircleModule";
import TitleNameFormDialog from "@components/cores/TitleNameFormDialog";
import DialogManager from "@modules/DialogManager";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '85vh',
  },
});

const cols = [
  { field: 'id', hide: true },
  { field: 'fanza_circle_id', headerName: 'サークルID', width: 150 },
  { field: 'dlsite_circle_id', headerName: 'DLsiteサークルID', width: 150 },
  { field: 'circle_name', headerName: 'サークル名', width: 400 }
];

export default function SettingTitlePage() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([])
  const [ filter, setFilter ] = React.useState('');
  const [ is_related, setRelated ] = React.useState(false);
  const filterRows = (txt, is_rel) => {
    txt = txt.toLowerCase();
    return CircleModule.relations.filter((r) => {
      return (
        (
          r.fanza_circle_id.toLowerCase().indexOf(txt) >= 0 ||
          r.dlsite_circle_id.toLowerCase().indexOf(txt) >= 0 ||
          r.circle_name.toLowerCase().indexOf(txt) >= 0
        ) && (
          is_rel === true ||
          r.is_contents_related === 0
        )
      );
    });
  };

  React.useEffect(() => {
    CircleModule.getRelations(false, () => {
      setLoading(false);
      setRows(filterRows('', false));
    });
  }, []);

  const refreshRows = () => {
    setRows(filterRows(filter, is_related));
  };

  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
    setRows(filterRows(e.target.value, is_related));
  };

  const handleChangeRelated = (e) => {
    setRelated(e.target.checked);
    setRows(filterRows(filter, e.target.checked));
  };

  const content = (
    <>
      <TitleNameFormDialog />
      <Grid container spacing={3}>

        <Grid item xs={12} md={8} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <Title>
              タイトル紐付け対象サークル
              <FormControlLabel
                sx={{ float: 'right', marginLeft: 3 }}
                control={<Checkbox checked={is_related} />}
                label="「紐付け作業完了」も表示"
                onChange={handleChangeRelated}
              />
              <TextField
                type="search"
                size="small"
                label="Filter"
                variant="outlined"
                value={filter}
                onChange={handleChangeFilter}
                sx={{ float: 'right' }}
              />
            </Title>
            <DataGrid
              style={{ height: '100%' }}
              columns={cols}
              rows={rows}
              onRowClick={(params) => {
                DialogManager.show(params.row, refreshRows);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
  return <PageTemplate content={content} />;
}
