import React from 'react';
import clsx from "clsx";
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import Genres1Module from "@modules/Genres1Module";
import Genre1FormDialog from "@components/cores/Genre1FormDialog";
import DialogManager from "@modules/DialogManager";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 600,
  },
});


export default function SettingGenre1Page() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [ rows, setRows ] = React.useState([])
  React.useEffect(() => {
    Genres1Module.getRelations((data) => {
      setRows(data);
    });
  }, []);

  const updateRow = (row) => {
    let newRows = rows.map((r) => {
      if (r.dlsite_genre1 !== row.dlsite_genre1)
        return r;

      return {
        dlsite_genre1: r.dlsite_genre1,
        dlsite_genre_name: r.dlsite_genre_name,
        genre1: row.genre1,
        genre_name: row.genre_name
      };
    });
    setRows(newRows);
  };

  const content = (
    <Grid container spacing={3}>
      <Genre1FormDialog />

      <Grid item xs={12} md={8} lg={12}>
        <Paper className={fixedHeightPaper}>

          <Title>DLsiteジャンル紐付け表</Title>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>DLsiteジャンルID</TableCell>
                <TableCell>DLsiteジャンル名</TableCell>
                <TableCell>ジャンルID</TableCell>
                <TableCell>ジャンル名</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.dlsite_genre1} hover onClick={() => {
                  DialogManager.show(row, updateRow);
                }}>
                  <TableCell>{row.dlsite_genre1}</TableCell>
                  <TableCell>{row.dlsite_genre_name}</TableCell>
                  <TableCell>{row.genre1}</TableCell>
                  <TableCell>{row.genre_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Paper>
      </Grid>
    </Grid>
  );
  return <PageTemplate content={content} />;
}
