import BaseApiModule from "./BaseApiModule";
import { genres2 } from "@data/genres2";


export default class Genres2Module extends BaseApiModule {
  static getList() {
    return genres2;
  }

  static getRow(genre2) {
    for (let i = 0; i < genres2.length; i++) {
      const row = genres2[i];
      if (row.genre2 === genre2)
        return row;
    }
    return null;
  }

  static addRow(row) {
    genres2.push(row);
  }

  static getRelations(fnc) {
    this.post(
      '/genre2/get_relations',
      {},
      (res) => { fnc(res.data); }
    );
  }

  static saveRelation(dlsite_genre2, genre2, genre_name, fnc) {
    let params = {
      dlsite_genre2: dlsite_genre2,
      genre2: genre2
    }
    if (genre2 === '0') {
      params['genre_name'] = genre_name;
    }
    if (!fnc) fnc = () => {};

    this.post(
      '/genre2/save_relation',
      params,
      fnc
    );
  }

  static stringMatchPoint(s1, s2) {
    let t = 0;
    if (s2.search(s1) > -1) return 1000;
    if (s1.search(s2) > -1) return 1000;
    for (let i = 0; i < s1.length; i++) {
      const p = s2.indexOf(s1.charAt(i));
      if (p > -1) t++;
    }
    return t;
  }

  static searchGenre(src_genre_name) {
    for (let i = 0; i < genres2.length; i++) {
      const r = genres2[i];
      if (src_genre_name === r.genre_name) {
        return r.genre2;
      }
    }

    let a = [];
    for (let i = 0; i < genres2.length; i++) {
      const r = genres2[i];
      const p = this.stringMatchPoint(
        src_genre_name,
        r.genre_name
      );
      a.push({
        genre2: r.genre2,
        point: p
      });
    }
    a.sort((a, b) => {
      if (a.point > b.point) return -1;
      if (a.point < b.point) return 1;
      return 0;
    });
    if (a[0].point > 0) {
      return a[0].genre2;
    }

    return '0';
  }
}
