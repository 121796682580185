export const targets = [
  {
    "target": 1,
    "target_name": "全年齢",
    "sort_no": 1
  },
  {
    "target": 2,
    "target_name": "同人",
    "sort_no": 2
  },
  {
    "target": 3,
    "target_name": "TL/乙女向け",
    "sort_no": 3
  },
  {
    "target": 4,
    "target_name": "BL",
    "sort_no": 4
  }
]
