/**
 * ダッシュボード用のパーツ
 */

import React from 'react';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { blueGrey } from "@mui/material/colors";
import TotalModule from "@modules/TotalModule";

const Label = function (props) {
  return (
    <Paper
      elevation="1"
      sx={{ padding: 2, backgroundColor: blueGrey[50], marginBottom: 1 }}
    >
      {props.title}
      <Typography
        sx={{ fontSize: 30, width: '100%', textAlign: 'right' }}
      >
        {props.txt}
      </Typography>
    </Paper>
  );
};

export default function TotalReport() {
  const [ report, setReport ] = React.useState({
    err: 0,
    dlsite_countents_count: 0,
    fanza_countents_count: 0
  });

  React.useEffect(() => {
    TotalModule.getReport((res) => {
      if (res.err) return;
      setReport(res);
    });
  }, []);

  return (
    <>
      <Label
        title="FANZAコンテンツ総数:"
        txt={report.fanza_countents_count.toLocaleString()}
      />
      <Label
        title="DLsiteコンテンツ総数:"
        txt={report.dlsite_countents_count.toLocaleString()}
      />
    </>
  );
}
