import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import AlertDialogManager from '@modules/AlertDialogManager';
import ContentComparisonDialog from "./ContentComparisonDialog";
import DialogManager from "@modules/DialogManager";
import ContentModule from "@modules/ContentModule";


export default function ContentComparisonForm() {
  const [site_content_id, setSiteContentID] = React.useState('');
  const handleClickSearch = () => {
    ContentModule.searchContentID(site_content_id, (data) => {
      let err = data.err;
      if (err === 1) {
        AlertDialogManager.show('ERROR', 'IDが不正です。');
      } else if (err === 104) {
        AlertDialogManager.show('ERROR', '対象データが存在しませんでした。');
      } else if (err === 199) {
        AlertDialogManager.show('ERROR', '対象データに不具合が存在します。対象IDを連絡ください。');
      } else if (err === 101) {
        AlertDialogManager.show('ERROR', 'FANZA、DLsiteの両方に存在するタイトルでないと比較できません。');
      } else if (err > 0) {
        AlertDialogManager.show('ERROR', '予期しないエラーが発生しました。');
      } else {
        DialogManager.show({
          cid: data.content_id,
          title: data.title
        }, null);
      }
    });
  };

  return (
    <Box
      component="form"
      noValidate
    >
      <ContentComparisonDialog />
      <TextField
        id="content-id"
        label="ID"
        variant="outlined"
        size="small"
        value={site_content_id}
        onChange={(e) => {
          setSiteContentID(e.target.value);
        }}
      />
      <Button
        variant="contained"
        size="large"
        onClick={handleClickSearch}
      >比較</Button>
    </Box>
  );
}
