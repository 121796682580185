import React from 'react';

import PageTemplate from "@components/cores/PageTemplate";
import RankingForm from "@components/cores/RankingForm";

export default function RankingDLsitePage() {
  const content = <RankingForm
    site_id="dlsite"
    genres1={[
      { val: 'TOTAL', label: '総合' },
      { val: 'COMIC', label: 'コミック+CG' },
      { val: 'GAME', label: 'ゲーム+動画' },
      { val: 'VOICE', label: 'ボイス+ASMR' }
    ]}
  />
  return <PageTemplate content={content} />;
}
