import BaseApiModule from "./BaseApiModule";
import { genres1 } from "@data/genres1";


export default class Genres1Module extends BaseApiModule {
  static getList() {
    return genres1;
  }

  static getRow(genre1) {
    for (let i = 0; i < genres1.length; i++) {
      const row = genres1[i];
      if (row.genre1 === genre1)
        return row;
    }
    return null;
  }

  static addRow(row) {
    genres1.push(row);
  }

  static getRelations(fnc) {
    this.post(
      '/genre1/get_relations',
      {},
      (res) => { fnc(res.data); }
    );
  }

  static saveRelation(dlsite_genre1, genre1, genre_name, fnc) {
    let params = {
      dlsite_genre1: dlsite_genre1,
      genre1: genre1
    }
    if (genre1 === '0') {
      params['genre_name'] = genre_name;
    }
    if (!fnc) fnc = () => {};

    this.post(
      '/genre1/save_relation',
      params,
      fnc
    );
  }

  static getSelectList() {
    let res = [{
      val: 0,
      label: 'すべて'
    }];
    for (let i = 0; i < 5; i++) {
      res.push({
        val: genres1[i].genre1,
        label: genres1[i].genre_name
      })
    }
    return res;
  }
}
