import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {ProtectedLayout} from "./routes/ProtectedLayout";

import LoginPage from "@components/pages/LoginPage";
import DashboardPage from "@components/pages/DashboardPage.js";
import TotalDailyPage from "@components/pages/TotalDailyPage";
import TotalMonthlyPage from "@components/pages/TotalMonthlyPage";
import TotalYearlyPage from "@components/pages/TotalYearlyPage";
import RankingFanzaPage from "@components/pages/RankingFanzaPage";
import RankingDLsitePage from "@components/pages/RankingDLsitePage";
import SettingGenre1Page from "@components/pages/SettingGenre1Page";
import SettingGenre2Page from "@components/pages/SettingGenre2Page";
import SettingCirclePage from "@components/pages/SettingCirclePage";
import SettingTitlePage from "@components/pages/SettingTitlePage";
import SettingUserPage from "@components/pages/SettingUserPage"
import BatchTimeAxisPage from "@components/pages/BatchTimeAxisPage";
import BatchContentsPage from "@components/pages/BatchContentsPage";

const App = () => {
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route element={<ProtectedLayout />}>
        <Route path='/' element={<DashboardPage />} />
        <Route path='/totaldaily' element={<TotalDailyPage />} />
        <Route path='/totalmonthly' element={<TotalMonthlyPage />} />
        <Route path='/totalyearly' element={<TotalYearlyPage />} />
        <Route path='/batchtimeaxis' element={<BatchTimeAxisPage />} />
        <Route path='/batchcontents' element={<BatchContentsPage />} />
        <Route path='/rankingfanza' element={<RankingFanzaPage />} />
        <Route path='/rankingdlsite' element={<RankingDLsitePage />} />
        <Route path='/settinggenre1' element={<SettingGenre1Page />} />
        <Route path='/settinggenre2' element={<SettingGenre2Page />} />
        <Route path='/settingcircle' element={<SettingCirclePage />} />
        <Route path='/settingtitle' element={<SettingTitlePage />} />
        <Route path='/settinguser' element={<SettingUserPage />} />
      </Route>
    </Routes>
  );
};

export default App;
