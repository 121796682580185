import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from "@mui/material/Link";
import IconButton from '@mui/material/IconButton';
import CompareIcon from '@mui/icons-material/Compare';

import DialogManager from "@modules/DialogManager";
import ContentComparisonDialog from "./ContentComparisonDialog";


export default function RankingTable(props) {

  return (
    <>
      <ContentComparisonDialog />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>比較</TableCell>
            <TableCell>ジャンル(大)</TableCell>
            <TableCell align="right">販売数</TableCell>
            <TableCell align="right">販売金額</TableCell>
            <TableCell align="right">売上金額</TableCell>
            <TableCell align="right">お気に入り数</TableCell>
            <TableCell align="right">値引率</TableCell>
            <TableCell align="right">還元率</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, idx) => (
            <TableRow key={'row-'+idx}>
              <TableCell>{row.rank_no}</TableCell>
              <TableCell>
                <Link href={row.link} target="_blank" rel="noreferrer">{row.title}</Link>
              </TableCell>
              <TableCell>
                {row.is_related
                  ? (
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        onClick={() => {
                          DialogManager.show(row, null);
                        }}
                    >
                      <CompareIcon/>
                    </IconButton>
                  )
                  : (
                    <span> </span>
                  )
                }
              </TableCell>
              <TableCell>{row.genre_name}</TableCell>
              <TableCell align="right">{row.sales}</TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">{row.profit}</TableCell>
              <TableCell align="right">{row.favorites}</TableCell>
              <TableCell align="right">{row.off}</TableCell>
              <TableCell align="right">{row.point_return}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
