import BaseApiModule from "./BaseApiModule";


export default class TitleModule extends BaseApiModule {
  static getRelations(circle_id, fnc) {
    this.post(
      '/title/get_relations',
      { circle_id: circle_id },
      (res) => {
        fnc(res.data);
      }
    );
  }

  static _searchRelation(relations) {
    let content_id = null;
    for (let i = 0; i < relations.length; i++) {
      if (relations[i].is_related === 1) {
        content_id = relations[i].content_id;
        break;
      }
    }
    return content_id;
  }

  static saveRelations(circle_id, relations, is_related, fnc) {
    let params = {
      circle_id: circle_id,
      is_related: is_related
    };

    let fanza_id = TitleModule._searchRelation(relations.fanza_relations);
    let dlsite_id = TitleModule._searchRelation(relations.dlsite_relations);
    if (fanza_id !== null && dlsite_id !== null) {
      params.save_relation = {
        fanza_id: fanza_id,
        dlsite_id: dlsite_id
      }
    }

    params.off_relations = [];
    for (let i = 0; i < relations.relations.length; i++) {
      const r = relations.relations[i];
      if (r.is_related === 0)
        params.off_relations.push(
          r.id
        );
    }

    this.post(
      '/title/save_relations',
      params,
      (res) => {
        if (res.data.err && res.data.err === 0) {

        }
        fnc(res.data);
      }
    );
  }

  static getSearchedCircles(cid, target_site, fnc) {
    this.post(
      '/circle/get_searched_circles',
      {
        id: cid,
        target_site: target_site
      },
      (res) => {
        fnc(res.data);
      }
    );
  }

  static getRelationCircle(cid, target_site) {
    if (cid === '') return null;
    if (this.relations.length === 0) return null;
    let row = null;

    if (target_site === 'fanza') {
      for (let idx = 0; idx < this.relations.length; idx++) {
        if (this.relations[idx].fanza_circle_id === cid) {
          row = this.relations[idx];
          break;
        }
      }
    } else if (target_site === 'dlsite') {
      for (let idx = 0; idx < this.relations.length; idx++) {
        if (this.relations[idx].dlsite_circle_id === cid) {
          row = this.relations[idx];
          break;
        }
      }
    }

    return row;
  }
}
