import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';

import LogoFanza from '@images/logo-fanza.png';
import LogoDLsite from '@images/logo-dlsite.png';
import DialogManager from '@modules/DialogManager';
import TitleModule from "@modules/TitleModule";
import CircleModule from "@modules/CircleModule";


class RelationManager {
  static relations = {
    relations: [],
    fanza_relations: [],
    dlsite_relations: []
  };

  static setRelation(idx, is_related) {
    RelationManager.relations.relations[idx].is_related = is_related;
  }

  static checkFnazaRelation(idx) {
    const cnt = RelationManager.relations.fanza_relations.length;
    for (let i = 0; i < cnt; i++) {
      let b = 0;
      if (i === idx) b = 1;
      RelationManager.relations.fanza_relations[i].is_related = b;
    }
  }

  static checkDLsiteRelation(idx) {
    const cnt = RelationManager.relations.dlsite_relations.length;
    for (let i = 0; i < cnt; i++) {
      let b = 0;
      if (i === idx) b = 1;
      RelationManager.relations.dlsite_relations[i].is_related = b;
    }
  }
}

const NotRelationsGrid = (props) => {
  const theme = createTheme();

  return (
    <Grid item xs={5.8}>
      <FormControl>
        <RadioGroup
          aria-labelledby={props.site_id + '-radio-buttons-group-label'}
          name={props.site_id + '-buttons-group'}
          onChange={(e) => {
            let idx = parseInt(e.target.value);
            if (props.site_id === 'fanza') {
              RelationManager.checkFnazaRelation(idx);
            } else {
              RelationManager.checkDLsiteRelation(idx);
            }
          }}
        >
          {props.relations.map((row, idx) =>
            row.is_related === 0 &&
              <Paper
                key={props.site_id + '-content-' + idx.toString()}
                sx={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}
              >
                <FormControlLabel
                  value={idx}
                  control={<Radio />}
                  label={row.title}
                />
              </Paper>
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

const NotRelationsTab = (props) => {
  return (
    <Grid container spacing={1} alignContent="center">
      <NotRelationsGrid site_id="fanza" relations={props.fanza_relations} />
      <NotRelationsGrid site_id="dlsite" relations={props.dlsite_relations} />
    </Grid>
  );
};

const RelationContent = (props) => {
  return (
    <Box
      sx={{
        p: 1
      }}
    >
      {props.title}
    </Box>
  );
};

const RelationRow = (row) => {
  const theme = createTheme();
  const [ is_relation, setRelation ] = React.useState(row.content.is_related);
  const [ justify_content, setJustifyContent ] = React.useState('center');

  const handleClickRelation = () => {
    if (row.content.is_related === 1) {
      RelationManager.setRelation(row.idx, 0);
      setRelation(false);
      setJustifyContent('space-between');
    } else {
      RelationManager.setRelation(row.idx, 1);
      setRelation(true);
      setJustifyContent('center');
    }
  };

  return (
    <ListItem sx={{ p: 0.5 }}>
      <Paper sx={{
        display: 'flex',
        justifyContent: justify_content,
        width: '100%'
      }}>
        <RelationContent title={row.content.fanza_title} />
        <Box
          sx={{
            paddingTop: 1,
            cursor: 'pointer',
            '&:hover': { bgcolor: theme.palette.grey.A100 },
            borderRadius: 2
          }}
          onClick={handleClickRelation}
        >
          {is_relation
            ? <>
              <ArrowForwardIcon color="primary" />
              <CheckBoxIcon color="primary" />
              <ArrowBackIcon color="primary" />
            </>
            : <>
              <ArrowBackIcon color="disabled" />
              <CheckBoxOutlineBlankIcon color="disabled" />
              <ArrowForwardIcon color="disabled" />
            </>
          }
        </Box>
        <RelationContent title={row.content.dlsite_title} />
      </Paper>
    </ListItem>
  );
};

const RelationsTab = (props) => {
  return (
    <List>
      {props.relations.map((row, idx) =>
        <RelationRow content={row} idx={idx} key={'relation-row-'+idx.toString()} />
      )}
    </List>
  );
};

export default function TitleNameFormDialog() {
  const theme = createTheme();

  const [open, setOpen] = React.useState(false);
  const [circle, setCircle] = React.useState({
    id: 0,
    fanza_circle_id: "",
    dlsite_circle_id: "",
    circle_name: "",
    is_contents_related: 0
  });

  const [relations, setRelations] = React.useState({
    relations: [],
    fanza_relations: [],
    dlsite_relations: []
  });

  const [relation_type, setRelationType] = React.useState('off');
  const [is_related, setRelated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  DialogManager.init(setOpen, (c) => {
    setRelationType('off');
    TitleModule.getRelations(c.id, (data) => {
      setCircle({
        id: data.id,
        fanza_circle_id: data.fanza_circle_id,
        dlsite_circle_id: data.dlsite_circle_id,
        circle_name: data.circle_name,
        is_contents_related: c.is_contents_related
      });

      RelationManager.relations = {
        relations: data.relations,
        fanza_relations: data.fanza_relations,
        dlsite_relations: data.dlsite_relations,
      };
      setRelations({
        relations: data.relations,
        fanza_relations: data.fanza_relations,
        dlsite_relations: data.dlsite_relations,
      });
      setRelated(c.is_contents_related === 1);
      setLoading(false);
    });
  });

  const handleRlationTypeToggle = (e) => {
    setRelationType(e.target.value);
  };

  const handleRelated = (e) => {
    setRelated(e.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setLoading(true);
    TitleModule.saveRelations(circle.id, RelationManager.relations, is_related, (res) => {
      console.log(res);
      if ('err' in res && res.err === 0) {
        CircleModule.setTitleRelated(circle.id, is_related);
        DialogManager.commitFunction();
      }
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <FormControl sx={{ float: 'right' }}>
          <ToggleButtonGroup
            color="primary"
            value={relation_type}
            exclusive
            onClick={handleRlationTypeToggle}
          >
            <ToggleButton value="off">紐付け無し</ToggleButton>
            <ToggleButton value="on">紐付け済み</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <Typography sx={{ flex: 1 }} variant="h6" component="div">
          タイトル紐付け
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignContent="center">
          <Grid item xs={5.8}>
            <FormControl>
              <FormLabel id="fanza-group-label" sx={{ verticalAlign: "top" }}>
                <Tooltip title="サークル詳細ページに遷移" placement="left-start">
                  <Link href={'https://www.dmm.co.jp/dc/doujin/-/list/=/article=maker/id='+circle.fanza_circle_id+'/'} target="_blank">
                    <LaunchIcon />
                  </Link>
                </Tooltip>
                <img src={LogoFanza} alt="FANZA Logo" width="24" />
                {circle.circle_name}
              </FormLabel>
            </FormControl>
          </Grid>
          <Grid item xs={5.8}>
            <FormControl>
              <FormLabel id="dlsite-group-label" sx={{ verticalAlign: "top" }}>
                <Tooltip title="サークル詳細ページに遷移" placement="left-start">
                  <Link href={'https://www.dlsite.com/maniax/circle/profile/=/maker_id/'+circle.dlsite_circle_id+'.html'} target="_blank">
                    <LaunchIcon />
                  </Link>
                </Tooltip>
                <img src={LogoDLsite} alt="DLsite Logo" width="24" />
                {circle.circle_name}
              </FormLabel>
            </FormControl>
          </Grid>
        </Grid>

        {relation_type === 'on' ? (
            <RelationsTab relations={relations.relations} />
          ) : (
            <NotRelationsTab
              fanza_relations={relations.fanza_relations}
              dlsite_relations={relations.dlsite_relations}
            />
          )
        }

        <FormControl sx={{ float: 'right', marginTop: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={is_related} onChange={handleRelated} inputProps={{ 'aria-label': 'controlled' }} />}
              label="紐付け作業完了"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}