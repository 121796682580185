
export default class DialogManager {
  static _openSetter = () => {};
  static onShow = () => {};
  static commitFunction = () => {};

  /**
   * @param open_setter ダイアログの表示切り替え用ファンクション
   * @param on_show_fnc ダイアログ表示時に起動するファンクション
   */
  static init(open_setter, on_show_fnc) {
    this._openSetter = open_setter;
    if (on_show_fnc) this.onShow = on_show_fnc;
  }

  static show(c, commit_fnc) {
    this.onShow(c);
    if (commit_fnc) this.commitFunction = commit_fnc;
    this._openSetter(true);
  }
}
