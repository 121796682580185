import BaseApiModule from "./BaseApiModule";
import { targets } from "@data/targets";


export default class TargetsModule extends BaseApiModule {
  static getList() {
    return targets;
  }

  static getSelectList() {
    let res = [{
      val: 0,
      label: 'すべて'
    }];
    for (let i = 0; i < 4; i++) {
      res.push({
        val: targets[i].target,
        label: targets[i].target_name
      })
    }
    return res;
  }
}
