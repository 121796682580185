import Cookies from 'js-cookie';
import BaseApiModule from "./BaseApiModule";

export default class UserModule {
  static isAuthenticated() {
    if (!Cookies.get('session_id')) return false;
    return true;
  }

  static isAdmin() {
    return (Cookies.get('is_admin') == 1);
  }

  static login(userId, password, fnc) {
    BaseApiModule.post(
      '/auth/login',
      { user_id: userId, password: password },
      (res) => {
        if (res.data.err === 0) {
          Cookies.set('session_id', res.data.session_id);
          Cookies.set('is_admin', res.data.is_admin);
        }
        fnc();
      }
    );
  }

  static logout() {
    Cookies.remove('session_id');
  }

  static getSessionId() {
    return Cookies.get('session_id');
  }

  static getList(fnc) {
    BaseApiModule.post(
      '/user/get_list',
      {},
      (res) => {
        fnc(res.data);
      }
    );
  }

  static save(usr, fnc) {
    BaseApiModule.post(
      '/user/save',
      {
        user_id: usr.user_id,
        password: usr.password,
        user_name: usr.user_name,
        is_admin: usr.is_admin
      },
      (res) => {
        fnc(res.data);
      }
    );
  }

  static delete(user_id, fnc) {
    BaseApiModule.post(
      '/user/delete',
      {
        user_id: user_id
      },
      (res) => {
        fnc(res.data);
      }
    );
  }
}
