import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles"
import Grid from '@mui/material/Grid';
import {DataGrid} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CachedIcon from '@mui/icons-material/Cached';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

import PageTemplate from "@components/cores/PageTemplate";
import Title from "@components/cores/Title";
import DialogManager from "@modules/DialogManager";
import BatchModule from "@modules/BatchModule";
import AlartDialogManager from "@modules/AlertDialogManager";
import ConfirmDialogManager from "@modules/ConfirmDialogManager";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '85vh',
  },
});

export default function BatchPageTemplate(props) {
  const classes = useStyles();
  const [ loading, setLoading ] = React.useState(true);
  const [ rows, setRows ] = React.useState([]);

  const loadRows = () => {
    BatchModule.getRows(props.batch_type, (res) => {
      setLoading(false);
      if (res.data.err) {
        AlartDialogManager.show('Error.', 'DB Error.');
        return;
      }
      setRows(res.data.rows);
    })
  };

  const addRow = (row) => {
    setRows(rows.concat(row));
  };

  const deleteRow = (batch_id) => {
    setRows(rows.filter(row => row.id != batch_id));
  };

  const downloadResult = (batch_id) => {
    setLoading(true);
    BatchModule.getDownloadURL(batch_id, (res) => {
      setLoading(false);
      if (res.data.err) {
        AlartDialogManager.show('Download', 'Download error.');
        return;
      }
      const link = document.createElement('a');
      link.href = res.data.url;
      link.download = res.data.batch_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const statusCell = (params) => {
    const status = params.row.status;
    if (status == BatchModule.STATUS_NOT_STARTED) {
      return <span>WAIT</span>;
    } else if (status == BatchModule.STATUS_STARTED) {
      return <CircularProgress />;
    } else if (status == BatchModule.STATUS_COMPLETED) {
      return <FileDownloadIcon sx={{ cursor: 'pointer' }} onClick={() => {
        downloadResult(params.row.id);
      }} />;
    }
    return <></>;
  };

  const cols = [
    { field: 'id', headerName: 'ID', width: 60 },
    { field: 'created', headerName: '登録日', width: 150 },
    { field: 'batch_name', headerName: '処理名', width: 250 },
    { field: 'status', headerName: 'STATUS', width: 100, renderCell: statusCell, headerAlign: 'center', align: 'center' },
    { field: 'started', headerName: '処理開始' },
    { field: 'completed', headerName: '処理完了' },
    { field: 'delete', headerName: 'DELETE', width: 100, headerAlign: 'center', align: 'center', renderCell: (params) => (
      <DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => {
        ConfirmDialogManager.show(
          'CONFIRM',
          '削除してもよろしいですか？',
          'OK', 'CANCEL',
          (res) => {
            if (res == 0) return;
            BatchModule.delete(params.row.id, (r) => {
              if (r.data.err) {
                AlartDialogManager.show('DELETE', '削除エラー');
                return;
              }
              deleteRow(params.row.id);
            });
          }
        );
      }} />
    )}
  ];

  React.useEffect(() => {
    loadRows();
  }, []);

  const content = (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={12}>
          {props.dialog}
          <Paper className={classes.paper}>
            <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Title>
              バッチ処理
              <IconButton
                sx={{ float: 'right' }}
                aria-label="追加"
                onClick={() => {
                  DialogManager.show(null, (row) => {
                    addRow(row);
                  });
                }}
              >
                <PlaylistAddIcon fontSize="large" color="primary" />
              </IconButton>
              <IconButton
                sx={{ float: 'right' }}
                aria-label="Reload"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <CachedIcon fontSize="large" color="primary" />
              </IconButton>
            </Title>
            <DataGrid
              style={{ height: '100%' }}
              columns={cols}
              rows={rows}
            />
          </Paper>
        </Grid>
      </Grid>
  );
  return <PageTemplate content={content} />;
}
