export const genres2 = [
  {
    "genre2": "1001",
    "genre_name": "OL",
    "sort_no": 0
  },
  {
    "genre2": "1008",
    "genre_name": "メイド",
    "sort_no": 0
  },
  {
    "genre2": "1009",
    "genre_name": "モデル",
    "sort_no": 0
  },
  {
    "genre2": "1013",
    "genre_name": "看護婦・ナース",
    "sort_no": 0
  },
  {
    "genre2": "1014",
    "genre_name": "熟女",
    "sort_no": 0
  },
  {
    "genre2": "1015",
    "genre_name": "女医",
    "sort_no": 0
  },
  {
    "genre2": "1016",
    "genre_name": "女教師",
    "sort_no": 0
  },
  {
    "genre2": "1023",
    "genre_name": "秘書",
    "sort_no": 0
  },
  {
    "genre2": "1025",
    "genre_name": "未亡人",
    "sort_no": 0
  },
  {
    "genre2": "1029",
    "genre_name": "妊婦",
    "sort_no": 0
  },
  {
    "genre2": "1031",
    "genre_name": "痴女",
    "sort_no": 0
  },
  {
    "genre2": "1034",
    "genre_name": "ギャル",
    "sort_no": 0
  },
  {
    "genre2": "1039",
    "genre_name": "人妻",
    "sort_no": 0
  },
  {
    "genre2": "1040",
    "genre_name": "ウェイトレス",
    "sort_no": 0
  },
  {
    "genre2": "1042",
    "genre_name": "シスター",
    "sort_no": 0
  },
  {
    "genre2": "1083",
    "genre_name": "幼なじみ",
    "sort_no": 0
  },
  {
    "genre2": "1085",
    "genre_name": "変身ヒロイン",
    "sort_no": 0
  },
  {
    "genre2": "115",
    "genre_name": "ノベル",
    "sort_no": 0
  },
  {
    "genre2": "12",
    "genre_name": "3DCG",
    "sort_no": 0
  },
  {
    "genre2": "13",
    "genre_name": "動画・アニメーション",
    "sort_no": 0
  },
  {
    "genre2": "15",
    "genre_name": "音声付き",
    "sort_no": 0
  },
  {
    "genre2": "150001",
    "genre_name": "サスペンス",
    "sort_no": 0
  },
  {
    "genre2": "150002",
    "genre_name": "スプラッター",
    "sort_no": 0
  },
  {
    "genre2": "150003",
    "genre_name": "ノンフィクション・体験談",
    "sort_no": 0
  },
  {
    "genre2": "150004",
    "genre_name": "パラレル",
    "sort_no": 0
  },
  {
    "genre2": "150005",
    "genre_name": "評論",
    "sort_no": 0
  },
  {
    "genre2": "150006",
    "genre_name": "料理",
    "sort_no": 0
  },
  {
    "genre2": "150007",
    "genre_name": "歴史",
    "sort_no": 0
  },
  {
    "genre2": "151001",
    "genre_name": "おむつ",
    "sort_no": 0
  },
  {
    "genre2": "151002",
    "genre_name": "ガーター",
    "sort_no": 0
  },
  {
    "genre2": "151003",
    "genre_name": "ガードル",
    "sort_no": 0
  },
  {
    "genre2": "151004",
    "genre_name": "カチューシャ",
    "sort_no": 0
  },
  {
    "genre2": "151005",
    "genre_name": "しっぽ",
    "sort_no": 0
  },
  {
    "genre2": "151006",
    "genre_name": "スーツ",
    "sort_no": 0
  },
  {
    "genre2": "151007",
    "genre_name": "スタンガン",
    "sort_no": 0
  },
  {
    "genre2": "151008",
    "genre_name": "ストッキング",
    "sort_no": 0
  },
  {
    "genre2": "151009",
    "genre_name": "スポユニ",
    "sort_no": 0
  },
  {
    "genre2": "151010",
    "genre_name": "チャイナ",
    "sort_no": 0
  },
  {
    "genre2": "151011",
    "genre_name": "ピアス・装飾品",
    "sort_no": 0
  },
  {
    "genre2": "151012",
    "genre_name": "ブレザー",
    "sort_no": 0
  },
  {
    "genre2": "151013",
    "genre_name": "ふんどし",
    "sort_no": 0
  },
  {
    "genre2": "151014",
    "genre_name": "ミリタリー",
    "sort_no": 0
  },
  {
    "genre2": "151015",
    "genre_name": "ムチ・縄・蝋燭",
    "sort_no": 0
  },
  {
    "genre2": "151016",
    "genre_name": "ラバー",
    "sort_no": 0
  },
  {
    "genre2": "151017",
    "genre_name": "リボン",
    "sort_no": 0
  },
  {
    "genre2": "151018",
    "genre_name": "ローレグ",
    "sort_no": 0
  },
  {
    "genre2": "151019",
    "genre_name": "ワイシャツ",
    "sort_no": 0
  },
  {
    "genre2": "151020",
    "genre_name": "下着",
    "sort_no": 0
  },
  {
    "genre2": "151021",
    "genre_name": "靴下",
    "sort_no": 0
  },
  {
    "genre2": "151022",
    "genre_name": "軍服",
    "sort_no": 0
  },
  {
    "genre2": "151023",
    "genre_name": "首輪・鎖・拘束具",
    "sort_no": 0
  },
  {
    "genre2": "151024",
    "genre_name": "男装",
    "sort_no": 0
  },
  {
    "genre2": "151025",
    "genre_name": "道着",
    "sort_no": 0
  },
  {
    "genre2": "151026",
    "genre_name": "白衣",
    "sort_no": 0
  },
  {
    "genre2": "151027",
    "genre_name": "半ズボン",
    "sort_no": 0
  },
  {
    "genre2": "151028",
    "genre_name": "包帯・注射器",
    "sort_no": 0
  },
  {
    "genre2": "152001",
    "genre_name": "オフィス・職場",
    "sort_no": 0
  },
  {
    "genre2": "152002",
    "genre_name": "ティーンズラブ",
    "sort_no": 0
  },
  {
    "genre2": "152003",
    "genre_name": "ブラチラ",
    "sort_no": 0
  },
  {
    "genre2": "152004",
    "genre_name": "ラブラブ・あまあま",
    "sort_no": 0
  },
  {
    "genre2": "152005",
    "genre_name": "遠距離恋愛",
    "sort_no": 0
  },
  {
    "genre2": "152006",
    "genre_name": "屋外",
    "sort_no": 0
  },
  {
    "genre2": "152007",
    "genre_name": "家族",
    "sort_no": 0
  },
  {
    "genre2": "152008",
    "genre_name": "丸呑み",
    "sort_no": 0
  },
  {
    "genre2": "152009",
    "genre_name": "石化",
    "sort_no": 0
  },
  {
    "genre2": "152010",
    "genre_name": "戦場",
    "sort_no": 0
  },
  {
    "genre2": "152011",
    "genre_name": "退廃・背徳・インモラル",
    "sort_no": 0
  },
  {
    "genre2": "152012",
    "genre_name": "電車",
    "sort_no": 0
  },
  {
    "genre2": "152013",
    "genre_name": "同棲",
    "sort_no": 0
  },
  {
    "genre2": "152014",
    "genre_name": "日常・生活",
    "sort_no": 0
  },
  {
    "genre2": "152015",
    "genre_name": "売春・援交",
    "sort_no": 0
  },
  {
    "genre2": "152016",
    "genre_name": "浮気",
    "sort_no": 0
  },
  {
    "genre2": "152017",
    "genre_name": "風俗・ソープ",
    "sort_no": 0
  },
  {
    "genre2": "152018",
    "genre_name": "恋人同士",
    "sort_no": 0
  },
  {
    "genre2": "152019",
    "genre_name": "憑依",
    "sort_no": 0
  },
  {
    "genre2": "153001",
    "genre_name": "オールハッピー",
    "sort_no": 0
  },
  {
    "genre2": "153002",
    "genre_name": "オカルト",
    "sort_no": 0
  },
  {
    "genre2": "153003",
    "genre_name": "おっぱい",
    "sort_no": 0
  },
  {
    "genre2": "153004",
    "genre_name": "お尻・ヒップ",
    "sort_no": 0
  },
  {
    "genre2": "153005",
    "genre_name": "ギャンブル",
    "sort_no": 0
  },
  {
    "genre2": "153006",
    "genre_name": "クイズ",
    "sort_no": 0
  },
  {
    "genre2": "153007",
    "genre_name": "シリアス",
    "sort_no": 0
  },
  {
    "genre2": "153008",
    "genre_name": "その他ゲーム",
    "sort_no": 0
  },
  {
    "genre2": "153009",
    "genre_name": "ソフトエッチ",
    "sort_no": 0
  },
  {
    "genre2": "153010",
    "genre_name": "タイピング",
    "sort_no": 0
  },
  {
    "genre2": "153011",
    "genre_name": "テーブルゲーム",
    "sort_no": 0
  },
  {
    "genre2": "153012",
    "genre_name": "デジタルノベル",
    "sort_no": 0
  },
  {
    "genre2": "153013",
    "genre_name": "ハードボイルド",
    "sort_no": 0
  },
  {
    "genre2": "153014",
    "genre_name": "バイオレンス",
    "sort_no": 0
  },
  {
    "genre2": "153015",
    "genre_name": "ほのぼの",
    "sort_no": 0
  },
  {
    "genre2": "153016",
    "genre_name": "ロールプレイング",
    "sort_no": 0
  },
  {
    "genre2": "153017",
    "genre_name": "感動",
    "sort_no": 0
  },
  {
    "genre2": "153018",
    "genre_name": "擬人化",
    "sort_no": 0
  },
  {
    "genre2": "153019",
    "genre_name": "脚",
    "sort_no": 0
  },
  {
    "genre2": "153020",
    "genre_name": "逆転無し",
    "sort_no": 0
  },
  {
    "genre2": "153021",
    "genre_name": "芸能",
    "sort_no": 0
  },
  {
    "genre2": "153022",
    "genre_name": "純愛",
    "sort_no": 0
  },
  {
    "genre2": "153023",
    "genre_name": "女主人公のみ",
    "sort_no": 0
  },
  {
    "genre2": "153024",
    "genre_name": "女性視点",
    "sort_no": 0
  },
  {
    "genre2": "153025",
    "genre_name": "淡白・あっさり",
    "sort_no": 0
  },
  {
    "genre2": "153026",
    "genre_name": "耽美",
    "sort_no": 0
  },
  {
    "genre2": "153027",
    "genre_name": "男無",
    "sort_no": 0
  },
  {
    "genre2": "153028",
    "genre_name": "伝奇",
    "sort_no": 0
  },
  {
    "genre2": "153029",
    "genre_name": "燃え",
    "sort_no": 0
  },
  {
    "genre2": "153030",
    "genre_name": "百合",
    "sort_no": 0
  },
  {
    "genre2": "153031",
    "genre_name": "萌え",
    "sort_no": 0
  },
  {
    "genre2": "153032",
    "genre_name": "癒し",
    "sort_no": 0
  },
  {
    "genre2": "153033",
    "genre_name": "鬱",
    "sort_no": 0
  },
  {
    "genre2": "154001",
    "genre_name": "インテリ",
    "sort_no": 0
  },
  {
    "genre2": "154002",
    "genre_name": "エルフ・妖精",
    "sort_no": 0
  },
  {
    "genre2": "154003",
    "genre_name": "おかっぱ",
    "sort_no": 0
  },
  {
    "genre2": "154004",
    "genre_name": "ガテン系",
    "sort_no": 0
  },
  {
    "genre2": "154005",
    "genre_name": "けもの・獣化",
    "sort_no": 0
  },
  {
    "genre2": "154006",
    "genre_name": "サラリーマン",
    "sort_no": 0
  },
  {
    "genre2": "154007",
    "genre_name": "スポーツ選手",
    "sort_no": 0
  },
  {
    "genre2": "154008",
    "genre_name": "タトゥー・刺青",
    "sort_no": 0
  },
  {
    "genre2": "154009",
    "genre_name": "ツインテール",
    "sort_no": 0
  },
  {
    "genre2": "154010",
    "genre_name": "つるぺた",
    "sort_no": 0
  },
  {
    "genre2": "154011",
    "genre_name": "デブ",
    "sort_no": 0
  },
  {
    "genre2": "154012",
    "genre_name": "ドジっ娘",
    "sort_no": 0
  },
  {
    "genre2": "154013",
    "genre_name": "ボクっ娘",
    "sort_no": 0
  },
  {
    "genre2": "154014",
    "genre_name": "ポニーテール",
    "sort_no": 0
  },
  {
    "genre2": "154015",
    "genre_name": "ヤリチン・プレイボーイ",
    "sort_no": 0
  },
  {
    "genre2": "154016",
    "genre_name": "ヤンデレ",
    "sort_no": 0
  },
  {
    "genre2": "154017",
    "genre_name": "ロボット",
    "sort_no": 0
  },
  {
    "genre2": "154018",
    "genre_name": "委員長",
    "sort_no": 0
  },
  {
    "genre2": "154019",
    "genre_name": "陰毛・腋毛",
    "sort_no": 0
  },
  {
    "genre2": "154020",
    "genre_name": "外国人",
    "sort_no": 0
  },
  {
    "genre2": "154021",
    "genre_name": "褐色・日焼け",
    "sort_no": 0
  },
  {
    "genre2": "154022",
    "genre_name": "義姉",
    "sort_no": 0
  },
  {
    "genre2": "154023",
    "genre_name": "義妹",
    "sort_no": 0
  },
  {
    "genre2": "154024",
    "genre_name": "巨根",
    "sort_no": 0
  },
  {
    "genre2": "154025",
    "genre_name": "金髪",
    "sort_no": 0
  },
  {
    "genre2": "154026",
    "genre_name": "後輩",
    "sort_no": 0
  },
  {
    "genre2": "154027",
    "genre_name": "三つ編み",
    "sort_no": 0
  },
  {
    "genre2": "154028",
    "genre_name": "実姉",
    "sort_no": 0
  },
  {
    "genre2": "154029",
    "genre_name": "実妹",
    "sort_no": 0
  },
  {
    "genre2": "154030",
    "genre_name": "叔父・義父",
    "sort_no": 0
  },
  {
    "genre2": "154031",
    "genre_name": "少女",
    "sort_no": 0
  },
  {
    "genre2": "154032",
    "genre_name": "少年",
    "sort_no": 0
  },
  {
    "genre2": "154033",
    "genre_name": "人外娘・モンスター娘",
    "sort_no": 0
  },
  {
    "genre2": "154034",
    "genre_name": "先輩",
    "sort_no": 0
  },
  {
    "genre2": "154035",
    "genre_name": "戦士",
    "sort_no": 0
  },
  {
    "genre2": "154036",
    "genre_name": "双子",
    "sort_no": 0
  },
  {
    "genre2": "154037",
    "genre_name": "体育会系",
    "sort_no": 0
  },
  {
    "genre2": "154038",
    "genre_name": "茶髪",
    "sort_no": 0
  },
  {
    "genre2": "154039",
    "genre_name": "天使・悪魔",
    "sort_no": 0
  },
  {
    "genre2": "154040",
    "genre_name": "天然",
    "sort_no": 0
  },
  {
    "genre2": "154041",
    "genre_name": "乳首・乳輪",
    "sort_no": 0
  },
  {
    "genre2": "154042",
    "genre_name": "不良・ヤンキー",
    "sort_no": 0
  },
  {
    "genre2": "154043",
    "genre_name": "保健医",
    "sort_no": 0
  },
  {
    "genre2": "154044",
    "genre_name": "母親",
    "sort_no": 0
  },
  {
    "genre2": "154045",
    "genre_name": "包茎",
    "sort_no": 0
  },
  {
    "genre2": "154046",
    "genre_name": "無表情",
    "sort_no": 0
  },
  {
    "genre2": "154047",
    "genre_name": "爺",
    "sort_no": 0
  },
  {
    "genre2": "154048",
    "genre_name": "幽霊・ゾンビ",
    "sort_no": 0
  },
  {
    "genre2": "154049",
    "genre_name": "妖怪",
    "sort_no": 0
  },
  {
    "genre2": "154050",
    "genre_name": "ミニ系",
    "sort_no": 0
  },
  {
    "genre2": "155001",
    "genre_name": "おさわり",
    "sort_no": 0
  },
  {
    "genre2": "155002",
    "genre_name": "おもらし",
    "sort_no": 0
  },
  {
    "genre2": "155003",
    "genre_name": "オヤジ受け",
    "sort_no": 0
  },
  {
    "genre2": "155004",
    "genre_name": "きせかえ",
    "sort_no": 0
  },
  {
    "genre2": "155005",
    "genre_name": "クール攻め",
    "sort_no": 0
  },
  {
    "genre2": "155006",
    "genre_name": "クール受け",
    "sort_no": 0
  },
  {
    "genre2": "155007",
    "genre_name": "スパンキング",
    "sort_no": 0
  },
  {
    "genre2": "155008",
    "genre_name": "ヘタレ攻め",
    "sort_no": 0
  },
  {
    "genre2": "155009",
    "genre_name": "やんちゃ受け",
    "sort_no": 0
  },
  {
    "genre2": "155010",
    "genre_name": "異種姦",
    "sort_no": 0
  },
  {
    "genre2": "155011",
    "genre_name": "乙女受け",
    "sort_no": 0
  },
  {
    "genre2": "155012",
    "genre_name": "俺様攻め",
    "sort_no": 0
  },
  {
    "genre2": "155013",
    "genre_name": "拡張",
    "sort_no": 0
  },
  {
    "genre2": "155014",
    "genre_name": "機械姦",
    "sort_no": 0
  },
  {
    "genre2": "155015",
    "genre_name": "強気受け",
    "sort_no": 0
  },
  {
    "genre2": "155016",
    "genre_name": "健気受け",
    "sort_no": 0
  },
  {
    "genre2": "155017",
    "genre_name": "言葉責め",
    "sort_no": 0
  },
  {
    "genre2": "155018",
    "genre_name": "搾乳",
    "sort_no": 0
  },
  {
    "genre2": "155019",
    "genre_name": "産卵",
    "sort_no": 0
  },
  {
    "genre2": "155020",
    "genre_name": "耳かき",
    "sort_no": 0
  },
  {
    "genre2": "155021",
    "genre_name": "焦らし",
    "sort_no": 0
  },
  {
    "genre2": "155022",
    "genre_name": "色仕掛け",
    "sort_no": 0
  },
  {
    "genre2": "155023",
    "genre_name": "人体改造",
    "sort_no": 0
  },
  {
    "genre2": "155024",
    "genre_name": "着衣",
    "sort_no": 0
  },
  {
    "genre2": "155025",
    "genre_name": "誘い受け",
    "sort_no": 0
  },
  {
    "genre2": "155026",
    "genre_name": "和姦",
    "sort_no": 0
  },
  {
    "genre2": "155027",
    "genre_name": "蟲姦",
    "sort_no": 0
  },
  {
    "genre2": "156001",
    "genre_name": "シリーズもの",
    "sort_no": 0
  },
  {
    "genre2": "156002",
    "genre_name": "ドット制作",
    "sort_no": 0
  },
  {
    "genre2": "156003",
    "genre_name": "断面図あり",
    "sort_no": 0
  },
  {
    "genre2": "156004",
    "genre_name": "動画",
    "sort_no": 0
  },
  {
    "genre2": "156005",
    "genre_name": "学園もの30%OFF",
    "sort_no": 0
  },
  {
    "genre2": "156006",
    "genre_name": "女性向け",
    "sort_no": 0
  },
  {
    "genre2": "156007",
    "genre_name": "コスプレ動画",
    "sort_no": 0
  },
  {
    "genre2": "156009",
    "genre_name": "ラブラブ作品30%OFF",
    "sort_no": 0
  },
  {
    "genre2": "156012",
    "genre_name": "VR対応",
    "sort_no": 0
  },
  {
    "genre2": "156021",
    "genre_name": "FANZA専売",
    "sort_no": 0
  },
  {
    "genre2": "156024",
    "genre_name": "コミケ92（2017夏）",
    "sort_no": 0
  },
  {
    "genre2": "156025",
    "genre_name": "コミケ93（2017冬）",
    "sort_no": 0
  },
  {
    "genre2": "156026",
    "genre_name": "コミケ94（2018夏）",
    "sort_no": 0
  },
  {
    "genre2": "156027",
    "genre_name": "コミケ95（2018冬）",
    "sort_no": 0
  },
  {
    "genre2": "156028",
    "genre_name": "コミケ96（2019夏）",
    "sort_no": 0
  },
  {
    "genre2": "156030",
    "genre_name": "コミケ97（2019冬）",
    "sort_no": 0
  },
  {
    "genre2": "156031",
    "genre_name": "がんばろう同人！",
    "sort_no": 0
  },
  {
    "genre2": "156032",
    "genre_name": "コミケ98（2020GW）",
    "sort_no": 0
  },
  {
    "genre2": "156033",
    "genre_name": "コミティア132extra",
    "sort_no": 0
  },
  {
    "genre2": "156034",
    "genre_name": "博麗神社例大祭第十七回",
    "sort_no": 0
  },
  {
    "genre2": "156035",
    "genre_name": "赤ブー5月イベント",
    "sort_no": 0
  },
  {
    "genre2": "156036",
    "genre_name": "YOU5月イベント",
    "sort_no": 0
  },
  {
    "genre2": "17",
    "genre_name": "ファンタジー",
    "sort_no": 0
  },
  {
    "genre2": "18",
    "genre_name": "ホラー",
    "sort_no": 0
  },
  {
    "genre2": "19",
    "genre_name": "アクセサリー",
    "sort_no": 0
  },
  {
    "genre2": "2001",
    "genre_name": "巨乳",
    "sort_no": 0
  },
  {
    "genre2": "2004",
    "genre_name": "めがね",
    "sort_no": 0
  },
  {
    "genre2": "2005",
    "genre_name": "貧乳・微乳",
    "sort_no": 0
  },
  {
    "genre2": "2006",
    "genre_name": "スレンダー",
    "sort_no": 0
  },
  {
    "genre2": "2007",
    "genre_name": "ぽっちゃり",
    "sort_no": 0
  },
  {
    "genre2": "2019",
    "genre_name": "ツンデレ",
    "sort_no": 0
  },
  {
    "genre2": "21",
    "genre_name": "残虐表現",
    "sort_no": 0
  },
  {
    "genre2": "22",
    "genre_name": "作家複数",
    "sort_no": 0
  },
  {
    "genre2": "23",
    "genre_name": "全年齢向け",
    "sort_no": 0
  },
  {
    "genre2": "25",
    "genre_name": "拘束",
    "sort_no": 0
  },
  {
    "genre2": "27",
    "genre_name": "辱め",
    "sort_no": 0
  },
  {
    "genre2": "28",
    "genre_name": "羞恥",
    "sort_no": 0
  },
  {
    "genre2": "3001",
    "genre_name": "体操着・ブルマ",
    "sort_no": 0
  },
  {
    "genre2": "3002",
    "genre_name": "和服・浴衣",
    "sort_no": 0
  },
  {
    "genre2": "300248",
    "genre_name": "エアコミケ2",
    "sort_no": 0
  },
  {
    "genre2": "3007",
    "genre_name": "ミニスカ",
    "sort_no": 0
  },
  {
    "genre2": "3008",
    "genre_name": "水着",
    "sort_no": 0
  },
  {
    "genre2": "3009",
    "genre_name": "競泳・スクール水着",
    "sort_no": 0
  },
  {
    "genre2": "30104",
    "genre_name": "パズル",
    "sort_no": 0
  },
  {
    "genre2": "3015",
    "genre_name": "ボンテージ",
    "sort_no": 0
  },
  {
    "genre2": "3033",
    "genre_name": "バニーガール",
    "sort_no": 0
  },
  {
    "genre2": "3034",
    "genre_name": "ゴスロリ",
    "sort_no": 0
  },
  {
    "genre2": "3035",
    "genre_name": "レオタード",
    "sort_no": 0
  },
  {
    "genre2": "3036",
    "genre_name": "女装・男の娘",
    "sort_no": 0
  },
  {
    "genre2": "37",
    "genre_name": "デモ・体験版あり",
    "sort_no": 0
  },
  {
    "genre2": "38",
    "genre_name": "クンニ",
    "sort_no": 0
  },
  {
    "genre2": "4001",
    "genre_name": "SM",
    "sort_no": 0
  },
  {
    "genre2": "4002",
    "genre_name": "近親相姦",
    "sort_no": 0
  },
  {
    "genre2": "4005",
    "genre_name": "乱交",
    "sort_no": 0
  },
  {
    "genre2": "4013",
    "genre_name": "レズ",
    "sort_no": 0
  },
  {
    "genre2": "4014",
    "genre_name": "童貞",
    "sort_no": 0
  },
  {
    "genre2": "4015",
    "genre_name": "ニューハーフ",
    "sort_no": 0
  },
  {
    "genre2": "4016",
    "genre_name": "パンチラ",
    "sort_no": 0
  },
  {
    "genre2": "4018",
    "genre_name": "スカトロ",
    "sort_no": 0
  },
  {
    "genre2": "4019",
    "genre_name": "パイパン",
    "sort_no": 0
  },
  {
    "genre2": "4020",
    "genre_name": "野外・露出",
    "sort_no": 0
  },
  {
    "genre2": "4021",
    "genre_name": "盗撮・のぞき",
    "sort_no": 0
  },
  {
    "genre2": "4031",
    "genre_name": "コスプレ",
    "sort_no": 0
  },
  {
    "genre2": "4058",
    "genre_name": "ショタ",
    "sort_no": 0
  },
  {
    "genre2": "4060",
    "genre_name": "ゲイ",
    "sort_no": 0
  },
  {
    "genre2": "4075",
    "genre_name": "SF",
    "sort_no": 0
  },
  {
    "genre2": "4076",
    "genre_name": "アクション",
    "sort_no": 0
  },
  {
    "genre2": "4096",
    "genre_name": "スポーツ",
    "sort_no": 0
  },
  {
    "genre2": "4111",
    "genre_name": "寝取り・寝取られ・NTR",
    "sort_no": 0
  },
  {
    "genre2": "4118",
    "genre_name": "アイドル・芸能人",
    "sort_no": 0
  },
  {
    "genre2": "4127",
    "genre_name": "アドベンチャー",
    "sort_no": 0
  },
  {
    "genre2": "48",
    "genre_name": "制服",
    "sort_no": 0
  },
  {
    "genre2": "5",
    "genre_name": "ギャグ・コメディ",
    "sort_no": 0
  },
  {
    "genre2": "5001",
    "genre_name": "中出し",
    "sort_no": 0
  },
  {
    "genre2": "5002",
    "genre_name": "フェラ",
    "sort_no": 0
  },
  {
    "genre2": "5003",
    "genre_name": "ぶっかけ",
    "sort_no": 0
  },
  {
    "genre2": "5005",
    "genre_name": "アナル",
    "sort_no": 0
  },
  {
    "genre2": "5006",
    "genre_name": "バイブ",
    "sort_no": 0
  },
  {
    "genre2": "5008",
    "genre_name": "オナニー",
    "sort_no": 0
  },
  {
    "genre2": "5009",
    "genre_name": "ごっくん",
    "sort_no": 0
  },
  {
    "genre2": "5010",
    "genre_name": "監禁",
    "sort_no": 0
  },
  {
    "genre2": "5011",
    "genre_name": "放尿",
    "sort_no": 0
  },
  {
    "genre2": "5014",
    "genre_name": "浣腸",
    "sort_no": 0
  },
  {
    "genre2": "5015",
    "genre_name": "ドラッグ",
    "sort_no": 0
  },
  {
    "genre2": "5017",
    "genre_name": "おもちゃ",
    "sort_no": 0
  },
  {
    "genre2": "5018",
    "genre_name": "ローター",
    "sort_no": 0
  },
  {
    "genre2": "5019",
    "genre_name": "パイズリ",
    "sort_no": 0
  },
  {
    "genre2": "5021",
    "genre_name": "縛り・緊縛",
    "sort_no": 0
  },
  {
    "genre2": "5022",
    "genre_name": "3P・4P",
    "sort_no": 0
  },
  {
    "genre2": "5023",
    "genre_name": "顔射",
    "sort_no": 0
  },
  {
    "genre2": "5057",
    "genre_name": "ローション",
    "sort_no": 0
  },
  {
    "genre2": "5059",
    "genre_name": "拷問",
    "sort_no": 0
  },
  {
    "genre2": "5060",
    "genre_name": "母乳",
    "sort_no": 0
  },
  {
    "genre2": "5068",
    "genre_name": "イラマチオ",
    "sort_no": 0
  },
  {
    "genre2": "5069",
    "genre_name": "くすぐり",
    "sort_no": 0
  },
  {
    "genre2": "5070",
    "genre_name": "時間停止",
    "sort_no": 0
  },
  {
    "genre2": "5071",
    "genre_name": "ハーレム",
    "sort_no": 0
  },
  {
    "genre2": "514",
    "genre_name": "アクション・格闘",
    "sort_no": 0
  },
  {
    "genre2": "524",
    "genre_name": "義母",
    "sort_no": 0
  },
  {
    "genre2": "527",
    "genre_name": "お嬢様・令嬢",
    "sort_no": 0
  },
  {
    "genre2": "528",
    "genre_name": "お姫様",
    "sort_no": 0
  },
  {
    "genre2": "55",
    "genre_name": "処女",
    "sort_no": 0
  },
  {
    "genre2": "553",
    "genre_name": "学園もの",
    "sort_no": 0
  },
  {
    "genre2": "555",
    "genre_name": "恋愛",
    "sort_no": 0
  },
  {
    "genre2": "558",
    "genre_name": "ボーイズラブ",
    "sort_no": 0
  },
  {
    "genre2": "569",
    "genre_name": "ラブコメ",
    "sort_no": 0
  },
  {
    "genre2": "59",
    "genre_name": "ふたなり",
    "sort_no": 0
  },
  {
    "genre2": "6003",
    "genre_name": "ベスト・総集編",
    "sort_no": 0
  },
  {
    "genre2": "6071",
    "genre_name": "アンソロジー",
    "sort_no": 0
  },
  {
    "genre2": "6098",
    "genre_name": "ミステリー",
    "sort_no": 0
  },
  {
    "genre2": "6149",
    "genre_name": "超乳",
    "sort_no": 0
  },
  {
    "genre2": "6164",
    "genre_name": "ビッチ",
    "sort_no": 0
  },
  {
    "genre2": "62",
    "genre_name": "触手",
    "sort_no": 0
  },
  {
    "genre2": "63",
    "genre_name": "巫女",
    "sort_no": 0
  },
  {
    "genre2": "64",
    "genre_name": "ネコミミ・獣系",
    "sort_no": 0
  },
  {
    "genre2": "7047",
    "genre_name": "女王様",
    "sort_no": 0
  },
  {
    "genre2": "7110",
    "genre_name": "シミュレーション",
    "sort_no": 0
  },
  {
    "genre2": "7111",
    "genre_name": "シューティング",
    "sort_no": 0
  },
  {
    "genre2": "72",
    "genre_name": "異物挿入",
    "sort_no": 0
  },
  {
    "genre2": "79",
    "genre_name": "劇画",
    "sort_no": 0
  },
  {
    "genre2": "8",
    "genre_name": "イラスト・CG集",
    "sort_no": 0
  },
  {
    "genre2": "88",
    "genre_name": "フィスト",
    "sort_no": 0
  },
  {
    "genre2": "93",
    "genre_name": "裸エプロン",
    "sort_no": 0
  },
  {
    "genre2": "94",
    "genre_name": "ニーソックス",
    "sort_no": 0
  },
  {
    "genre2": "95",
    "genre_name": "性転換・女体化",
    "sort_no": 0
  },
  {
    "genre2": "97",
    "genre_name": "筋肉",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_020",
    "genre_name": "キャットファイト",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_028",
    "genre_name": "歳の差",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_029",
    "genre_name": "魔法",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_040",
    "genre_name": "襲い受",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_042",
    "genre_name": "総攻",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_043",
    "genre_name": "強気攻",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_044",
    "genre_name": "総受",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_047",
    "genre_name": "やおい",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_050",
    "genre_name": "チラリズム",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_053",
    "genre_name": "健全",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_064",
    "genre_name": "フェチ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_066",
    "genre_name": "マニアック/変態",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_068",
    "genre_name": "淫語",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_069",
    "genre_name": "汁/液大量",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_070",
    "genre_name": "連続絶頂",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_075",
    "genre_name": "セーラー服",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_083",
    "genre_name": "パンツ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_100",
    "genre_name": "スパッツ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_112",
    "genre_name": "ノーマルプレイ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_113",
    "genre_name": "レイプ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_114",
    "genre_name": "強制/無理矢理",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_115",
    "genre_name": "逆レイプ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_117",
    "genre_name": "放置プレイ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_121",
    "genre_name": "輪姦",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_124",
    "genre_name": "手コキ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_125",
    "genre_name": "足コキ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_129",
    "genre_name": "妊娠/孕ませ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_139",
    "genre_name": "痴漢",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_140",
    "genre_name": "調教",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_142",
    "genre_name": "淫乱",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_147",
    "genre_name": "奴隷",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_154",
    "genre_name": "鬼畜",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_156",
    "genre_name": "男性受け",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_157",
    "genre_name": "催眠",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_166",
    "genre_name": "ショートカット",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_167",
    "genre_name": "ロングヘア",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_171",
    "genre_name": "黒髪",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_177",
    "genre_name": "長身",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_194",
    "genre_name": "巨大化",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_196",
    "genre_name": "関西弁",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_199",
    "genre_name": "猟奇",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_204",
    "genre_name": "血液/流血",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_205",
    "genre_name": "狂気",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_208",
    "genre_name": "ぷに",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_209",
    "genre_name": "年上",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_215",
    "genre_name": "娘",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_220",
    "genre_name": "お姉さん",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_221",
    "genre_name": "既婚者",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_231",
    "genre_name": "ホスト",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_240",
    "genre_name": "魔法少女",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_241",
    "genre_name": "魔法使い/魔女",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_268",
    "genre_name": "姉妹",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_270",
    "genre_name": "下克上",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_271",
    "genre_name": "リバ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_272",
    "genre_name": "ヤクザ/裏社会",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_274",
    "genre_name": "兄",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_275",
    "genre_name": "弟",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_279",
    "genre_name": "年下攻め",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_281",
    "genre_name": "同級生/同僚",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_284",
    "genre_name": "警察/刑事",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_288",
    "genre_name": "主従",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_293",
    "genre_name": "執事",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_304",
    "genre_name": "おやじ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_313",
    "genre_name": "リョナ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_317",
    "genre_name": "人外娘/モンスター娘",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_318",
    "genre_name": "社長",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_319",
    "genre_name": "医者",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_323",
    "genre_name": "アヘ顔",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_326",
    "genre_name": "洗脳",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_327",
    "genre_name": "レディースコミック",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_399",
    "genre_name": "青年",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_400",
    "genre_name": "学生",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_405",
    "genre_name": "レスラー/格闘家",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_420",
    "genre_name": "逆ハーレム",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_422",
    "genre_name": "俺様",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_424",
    "genre_name": "上司",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_425",
    "genre_name": "アラブ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_427",
    "genre_name": "王子様/王子系",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_450",
    "genre_name": "少年コミック",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_451",
    "genre_name": "少女コミック",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_452",
    "genre_name": "青年コミック",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_454",
    "genre_name": "動物/ペット",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_461",
    "genre_name": "尿道",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_462",
    "genre_name": "ノンケ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_463",
    "genre_name": "バイ",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_465",
    "genre_name": "モブ姦",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_478",
    "genre_name": "短髪",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_484",
    "genre_name": "腹パン",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_488",
    "genre_name": "口内射精",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_492",
    "genre_name": "英語作品",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_493",
    "genre_name": "オメガバース",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_494",
    "genre_name": "ロマンス",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_495",
    "genre_name": "睡眠姦",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_497",
    "genre_name": "ASMR",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_498",
    "genre_name": "DLsite翻訳作品",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_500",
    "genre_name": "耳舐め",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_501",
    "genre_name": "潮吹き",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_502",
    "genre_name": "男主人公",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_503",
    "genre_name": "ささやき",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_505",
    "genre_name": "くノ一",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_506",
    "genre_name": "サキュバス/淫魔",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_507",
    "genre_name": "技術書",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_508",
    "genre_name": "TRPG",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_509",
    "genre_name": "3D作品",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_510",
    "genre_name": "ツクール",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_512",
    "genre_name": "東方Project",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_513",
    "genre_name": "VTuber",
    "sort_no": 0
  },
  {
    "genre2": "DLSITE_514",
    "genre_name": "オナサポ",
    "sort_no": 0
  }
]
