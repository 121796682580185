import React from 'react';
import clsx from "clsx";
import Paper from "@mui/material/Paper";
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";

import RankingTable from "@components/cores/RankingTable";
import Title from "@components/cores/Title";
import DmmSelect from "@components/cores/DmmSelect";
import RankModule from "@modules/RankModule";


const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: 1200,
  },
  fixedHeight: {
  },
});

let term = RankModule.TERMS()[0].val;
let sort = RankModule.SORTS()[0].val;
let genre1 = RankModule.GENRES1()[0].val;
let target = RankModule.TARGETS()[1].val;
let date_key = '';

export default function RankingForm(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  let label = 'FANZA';
  if (props.site_id === 'dlsite')
    label = 'DLsite';

  const [ loading, setLoading ] = React.useState(true);
  const [ date_keys, setDateKeys ] = React.useState([]);
  const [ rows, setRows ] = React.useState([]);
  const loadRows = (ter, sor, gen1, targ, dk) => {
    let t = term;
    let s = sort;
    let g1 = genre1;
    let ta = target;
    let d = '';
    if (ter) t = ter;
    if (sor) s = sor;
    if (gen1) g1 = gen1;
    if (targ) ta = targ;
    if (dk) d = dk;
    RankModule.getRank(props.site_id, t, s, g1, ta, d, (res) => {
      setLoading(false);
      if (res.err) {
        alert('API Error.');
        return;
      }
      setRows(res.data);
      setDateKeys(res.date_keys);
      date_key = res.date_key;
    });
  };
  React.useEffect(loadRows, [ props.site_id ]);

  const changeTerm = (val) => {
    term = val;
    setRows([]);
    setLoading(true);
    loadRows(val, null, null, null, null);
  }
  const changeSort = (val) => {
    sort = val;
    setRows([]);
    setLoading(true);
    loadRows(null, val, null, null, null);
  }
  const changeGenre1 = (val) => {
    genre1 = val;
    setRows([]);
    setLoading(true);
    loadRows(null, null, val, null, null);
  }
  const changeTarget = (val) => {
    target = val;
    setRows([])
    setLoading(true);
    loadRows(null, null, null, val, null);
  }
  const changeDateKey = (val) => {
    date_key = val;
    setRows([]);
    setLoading(true);
    loadRows(null, null, null, null, val);
  }

  const handlePrintCsv = () => {
    RankModule.getCsv(props.site_id, term, sort, genre1, target, date_key);
  };

  return (
    <Paper className={fixedHeightPaper}>
      <Title>
        RANKING {label}
        <Button
          variant="contained"
          color="secondary"
          style={{ float: "right" }}
          onClick={handlePrintCsv}
        >
          CSV出力
        </Button>

      </Title>
      <div>
        <Backdrop open={loading} style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DmmSelect
          label="機関"
          labelId="term"
          val={term}
          onChange={changeTerm}
          items={RankModule.TERMS()}
        />
        <DmmSelect
          label="SORT"
          labelId="sort"
          val={sort}
          onChange={changeSort}
          items={RankModule.SORTS()}
        />
        <DmmSelect
          label="ジャンル(大)"
          labelId="genre1"
          val={genre1}
          onChange={changeGenre1}
          items={props.genres1}
        />
        <DmmSelect
          label="データ取得日"
          labelId="date-key"
          val={date_key}
          onChange={changeDateKey}
          items={date_keys}
        />
        {props.site_id === 'dlsite' &&
        <DmmSelect
          label="ターゲット層"
          labelId="target"
          val={target}
          onChange={changeTarget}
          items={RankModule.TARGETS()}
        />
        }
      </div>

      <RankingTable
        term="DAILY"
        sort="FAVORITE"
        genre="TOTAL"
        rows={rows}
      />
    </Paper>
  );
}
