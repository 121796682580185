import BaseApiModule from "./BaseApiModule";


export default class CircleModule extends BaseApiModule {
  static relations = [];

  static getRelations(not_flg, fnc) {
    let params = {};
    if (not_flg) {
      params.not = 1;
    }
    this.post(
      '/circle/get_relations',
      params,
      (res) => {
        this.relations = res.data;
        fnc(res.data);
      }
    );
  }

  static setTitleRelated(circle_id, is_related) {
    for (let i = 0; i < this.relations.length; i++) {
      const r = this.relations[i];
      if (r.id === circle_id) {
        if (is_related) {
          r.is_contents_related = 1;
        } else {
          r.is_contents_related = 0;
        }
        break;
      }
    }
  }

  static getSearchedCircles(cid, target_site, fnc) {
    this.post(
      '/circle/get_searched_circles',
      {
        id: cid,
        target_site: target_site
      },
      (res) => {
        fnc(res.data);
      }
    );
  }

  static getRelationCircle(cid, target_site) {
    if (cid === '') return null;
    if (this.relations.length === 0) return null;
    let row = null;

    if (target_site === 'fanza') {
      for (let idx = 0; idx < this.relations.length; idx++) {
        if (this.relations[idx].fanza_circle_id === cid) {
          row = this.relations[idx];
          break;
        }
      }
    } else if (target_site === 'dlsite') {
      for (let idx = 0; idx < this.relations.length; idx++) {
        if (this.relations[idx].dlsite_circle_id === cid) {
          row = this.relations[idx];
          break;
        }
      }
    }

    return row;
  }

  static saveRelation(src_id, target_id, fnc) {
    if (!fnc) fnc = () => {};
    this.post(
      '/circle/save_relation',
      { src_id: src_id, target_id: target_id },
      (res) => {
        fnc(res);
      }
    );
  }

  static setRelations(rows) {
    this.relations = rows;
  }
}
