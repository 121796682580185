import BaseApiModule from "./BaseApiModule";


export default class ContentModule extends BaseApiModule {
  static getTrendsDefaultOption() {
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    let dk = dt.getFullYear() + '-'
      + ('00' + (dt.getMonth() + 1)).slice(-2) + '-'
      + ('00' + dt.getDate()).slice(-2);
    let dateKeyEnd = dk;

    dt.setDate(dt.getDate() - 14);
    dk = dt.getFullYear() + '-'
      + ('00' + (dt.getMonth() + 1)).slice(-2) + '-'
      + ('00' + dt.getDate()).slice(-2);
    let dateKeyFrom = dk;

    return {
      comparison_type: 'rank',
      datekey_from: dateKeyFrom,
      datekey_end: dateKeyEnd
    };
  }

  static getTrends(cid, datekey_from, datekey_end, fnc) {
    this.post(
      '/content/trends',
      {
        content_id: cid,
        datekey_from: datekey_from,
        datekey_end: datekey_end
      },
      (res) => { fnc(res.data); }
    );
  }

  static searchContentID(scid, fnc) {
    this.post(
      '/content/search_cid',
      {
        site_content_id: scid
      },
      (res) => { fnc(res.data); }
    );
  }
}
