import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";

import DialogManager from '@modules/DialogManager';
import Genres1Module from '@modules/Genres1Module';


export default function Genre1FormDialog() {
  const genres1 = Genres1Module.getList();
  const theme = createTheme();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({
    dlsite_genre1: "",
    dlsite_genre_name: "",
    genre1: "0",
    genre_name: ""
  });
  DialogManager.init(setOpen, setRow);

  const handleGenre1Change = (e) => {
    const genre1 = e.target.value;
    let genre_name = row.genre_name;
    if (genre1 !== '0') {
      const r = Genres1Module.getRow(genre1);
      if (r) genre_name = r.genre_name;
    }
    setRow({
      dlsite_genre1: row.dlsite_genre1,
      dlsite_genre_name: row.dlsite_genre_name,
      genre1: genre1,
      genre_name: genre_name
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    Genres1Module.saveRelation(
      row.dlsite_genre1,
      row.genre1,
      row.dlsite_genre_name,
      (res) => {
        if ( ! res.data) {
          alert('DB登録に失敗しました。');
          return;
        }
        if (res.data.err) {
          alert('DB登録に失敗しました。');
          return;
        }
        let ro = {
          dlsite_genre1: row.dlsite_genre1,
          dlsite_genre_name: row.dlsite_genre_name,
          genre1: row.genre1,
          genre_name: row.genre_name
        };
        if (ro.genre1 === '0') {
          ro.genre1 = 'DLSITE_' + row.dlsite_genre1;
          ro.genre_name = row.dlsite_genre_name;
          Genres1Module.addRow(ro)
        }
        DialogManager.commitFunction(ro);
      }
    );
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">ジャンル紐付け</DialogTitle>
      <DialogContent>
        <Paper
          elevation={0}
          variant="outlined"
          style={{ marin: theme.spacing(3), padding: theme.spacing(2) }}
        >
          DLsiteジャンルID: {row.dlsite_genre1}<br />
          DLsiteジャンル名: {row.dlsite_genre_name}
        </Paper>
        <br />
        <FormControl
          variant="outlined"
          style={{ margin: theme.spacing(1), minWidth: 300 }}
        >
          <InputLabel id="genre1-label">変換先ジャンル</InputLabel>
          <Select
            labelId="genre1-label"
            id="genre1-select"
            label="変換先ジャンル"
            value={row.genre1}
            onChange={handleGenre1Change}
          >
            {genres1.map((r) =>
              <MenuItem key={r.genre1} value={r.genre1}>{r.genre_name}</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}