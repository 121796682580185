import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import DialogManager from '@modules/DialogManager';
import Genres2Module from '@modules/Genres2Module';


export default function Genre2FormDialog() {
  const genres2 = Genres2Module.getList();
  const theme = createTheme();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({
    dlsite_genre2: "",
    dlsite_genre_name: "",
    genre2: "0",
    genre_name: ""
  });
  DialogManager.init(setOpen, setRow);

  const handleGenre2Change = (e) => {
    const genre2 = e.target.value;
    let genre_name = row.genre_name;
    if (genre2 !== '0') {
      const r = Genres2Module.getRow(genre2);
      if (r) genre_name = r.genre_name;
    }
    setRow({
      dlsite_genre2: row.dlsite_genre2,
      dlsite_genre_name: row.dlsite_genre_name,
      genre2: genre2,
      genre_name: genre_name
    });
  };

  const handleSearchGenre = () => {
    let g = Genres2Module.searchGenre(row.dlsite_genre_name);
    if (g === '0') {
      alert('見つかりませんでした。');
      return;
    }
    const r = Genres2Module.getRow(g);
    let genre_name = '';
    if (r) genre_name = r.genre_name;
    setRow({
      dlsite_genre2: row.dlsite_genre2,
      dlsite_genre_name: row.dlsite_genre_name,
      genre2: g,
      genre_name: genre_name
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    Genres2Module.saveRelation(
      row.dlsite_genre2,
      row.genre2,
      row.dlsite_genre_name,
      (res) => {
        if ( ! res.data) {
          alert('DB登録に失敗しました。');
          return;
        }
        if (res.data.err) {
          alert('DB登録に失敗しました。');
          return;
        }
        let ro = {
          dlsite_genre2: row.dlsite_genre2,
          dlsite_genre_name: row.dlsite_genre_name,
          genre2: row.genre2,
          genre_name: row.genre_name
        };
        if (ro.genre2 === '0') {
          ro.genre2 = 'DLSITE_' + row.dlsite_genre2;
          ro.genre_name = row.dlsite_genre_name;
          Genres2Module.addRow(ro)
        }
        DialogManager.commitFunction(ro);
      }
    );
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">ジャンル紐付け</DialogTitle>
      <DialogContent>
        <Paper
          elevation={0}
          variant="outlined"
          style={{ marin: theme.spacing(3), padding: theme.spacing(2) }}
        >
          DLsiteジャンルID: {row.dlsite_genre2}<br />
          DLsiteジャンル名: {row.dlsite_genre_name}
        </Paper>
        <div align="center">
          <Button
            color="primary"
            style={{ margin: theme.spacing(1) }}
            startIcon={<AssignmentReturnedIcon />}
            onClick={handleSearchGenre}
          >
            似た変換先ジャンルを検索
          </Button>
        </div>
        <FormControl
          variant="outlined"
          style={{ margin: theme.spacing(1), minWidth: 300 }}
        >
          <InputLabel id="genre2-label">変換先ジャンル</InputLabel>
          <Select
            labelId="genre2-label"
            id="genre2-select"
            label="変換先ジャンル"
            value={row.genre2}
            onChange={handleGenre2Change}
          >
            <MenuItem value="0">新規作成</MenuItem>
            {genres2.map((r) =>
              <MenuItem key={r.genre2} value={r.genre2}>{r.genre_name}</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}