import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import AlertDialogManager from '@modules/AlertDialogManager';
import DialogManager from '@modules/DialogManager';
import ExcelIcon from '@images/excel.png';
import DmmSelect from "./DmmSelect";
import BatchModule from "@modules/BatchModule";
import Typography from "@mui/material/Typography";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function UploadExcelFile(props) {
  return (
    <Box sx={{ width: '100%', textAlign: 'center', padding: '1rem' }}>
      <img src={ExcelIcon} alt="Excel" width="150px"/>
      <Typography
        variant="inherit"
        component="div"
        sx={{ color: 'grey' }}
      >{props.excel_name}</Typography>
    </Box>
  );
}

export default function BatchContentsDialog() {
  const [open, setOpen] = React.useState(false);
  const [cols, setCols] = React.useState({});

  const [ site_id, setSiteID ] = React.useState('dlsite');
  const [ batch_name, setBatchName ] = React.useState('');
  const [ excel_name, setExcelName ] = React.useState('');
  const [ excel_b64, setExcelB64 ] = React.useState('');

  DialogManager.init(setOpen, () => {});
  const handleChecked = (e, k) => {
    let c = cols;
    if (e.target.checked) {
      c[k] = true;
    } else {
      delete c[k];
    }
    setCols(c);
  };
  const handleChangeSiteID = (val) => {
    setSiteID(val);
  };
  const handleChangeBatchName = (e) => {
    setBatchName(e.target.value);
  };

  const handleInputFile = (e) => {
    if (e.target.files.length === 0) return;
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.addEventListener("load", () => {
      setExcelB64(reader.result);
    });
    setExcelName(file.name);
    reader.readAsDataURL(file);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (typeof batch_name === 'string' && batch_name.length === 0) {
      AlertDialogManager.show('ERROR', '「処理名」を入力してください。');
      return;
    }
    if (typeof cols !== 'object' || Object.keys(cols).length === 0) {
      AlertDialogManager.show('ERROR', '「出力項目」を１つ以上選択してください。');
      return;
    }
    BatchModule.insertContents({
      cols: Object.keys(cols),
      site_id: site_id,
      batch_name: batch_name,
      excel_b64: excel_b64
    }, (res) => {
      if (res.data.err) {
        AlertDialogManager.show('ERROR', 'DB Error.');
        return 0;
      } else {
        DialogManager.commitFunction({
          id: res.data.batch_id,
          created: new Date().toLocaleDateString('sv-SE'),
          batch_name: batch_name,
          status: 0,
          started: null,
          completed: null
        });
        setOpen(false);
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">バッチ処理登録</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            '& > :not(style)': { m: 1, width: '60ch' },
          }}
        >
          <FormLabel sx={{ marginTop: 2 }}>基本情報</FormLabel>
          <DmmSelect
            label="SITE"
            labelId="site_id"
            val={site_id}
            onChange={handleChangeSiteID}
            items={[
              { val: 'dlsite', label: 'DLsite' },
              { val: 'fanza', label: 'FANZA' }
            ]}
          />
          <TextField
            id="batch_name"
            label="処理名"
            defaultValue={batch_name}
            value={batch_name}
            onChange={handleChangeBatchName}
            sx={{ margin: 1 }}
          />

          <Divider sx={{ marginTop: 4 }} />
          <FormControl component="fieldset">
            <FormLabel component="legend">出力項目</FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel control={
                <Checkbox checked={cols['profit']} onChange={(e) => {
                  handleChecked(e, 'profit');
                }} />
              } label="売上金額" labelPlacement="end" />
              <FormControlLabel control={
                <Checkbox checked={cols['sales_diff']} onChange={(e) => {
                  handleChecked(e, 'sales_diff');
                }} />
              } label="販売数" labelPlacement="end" />
              <FormControlLabel control={
                <Checkbox checked={cols['fans_diff']} onChange={(e) => {
                  handleChecked(e, 'fans_diff');
                }} />
              } label="お気に入り登録数" labelPlacement="end" />
            </FormGroup>

            <Divider sx={{ marginTop: 4 }} />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              UPLOAD EXCEL FILE
              <VisuallyHiddenInput
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleInputFile}
              />
            </Button>
            {excel_name.length > 0 && (
              <UploadExcelFile excel_name={excel_name} />
            )}
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}