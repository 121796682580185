
let _account = {};

export default class DataManager
{
    static isLogin() {
        return ('is_login' in _account);
    }

    static login(account) {
        _account.is_login = true;
        _account.name = account.name;
    }
}
