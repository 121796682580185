import React from 'react';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import createTheme from "@mui/material/styles/createTheme";
import makeStyles from "@mui/styles/makeStyles";
import Link from "@mui/material/Link";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const theme = createTheme();
const useStyles = makeStyles({
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 360,
  },
  title: {
    cursor: "pointer",
    fontSize: 20
  }
});

export default function Report(props) {
  const classes= useStyles();

  return (
    <Grid item xs={12} md={8} lg={6}>
      <Card>
        <CardHeader
          title={props.link
            ? (
              <Link
                href={props.link}
                className={classes.title}
              >
                {props.title}
              </Link>
            )
            : <>{props.title}</>
          }
        />
        <CardContent className={classes.card}>
          {props.content}
        </CardContent>
      </Card>
    </Grid>
  );
}