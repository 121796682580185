import BaseApiModule from "./BaseApiModule";

export default class TotalModule {
  static COL_ITEMS = [
    { val: 'profit', label: '売上金額' },
    { val: 'sales', label: '販売数' },
    { val: 'favorites', label: 'お気に入り登録数' },
    { val: 'increase', label: '増加数' }
  ];

  static FRESHNESS_ITEMS = [
    { val: 0, label: 'すべて' },
    { val: 1, label: '新作' },
    { val: 2, label: '旧作' }
  ];

  static AI_ITEMS = [
    { val: 0, label: 'すべて' },
    { val: 1, label: 'AI作品' },
    { val: 2, label: 'AI一部利用' },
    { val: 3, label: 'AI作品 + AI一部利用' }
  ];

  static getDailyDefaultOption() {
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    let dk = dt.getFullYear() + '-'
      + ('00' + (dt.getMonth() + 1)).slice(-2) + '-'
      + ('00' + dt.getDate()).slice(-2);
    let dateKeyEnd = dk;

    dt.setDate(dt.getDate() - 14);
    dk = dt.getFullYear() + '-'
      + ('00' + (dt.getMonth() + 1)).slice(-2) + '-'
      + ('00' + dt.getDate()).slice(-2);
    let dateKeyFrom = dk;

    return {
      totalCol: 'profit',
      dateKeyFrom: dateKeyFrom,
      dateKeyEnd: dateKeyEnd,
      genre1: 0,
      target: 0,
      freshness: 0,
      ai: 0
    };
  }

  static getDailyData(props, fnc) {
    BaseApiModule.post(
      '/total/daily',
      {
        total_col: props.totalCol,
        datekey_from: props.dateKeyFrom,
        datekey_end: props.dateKeyEnd,
        genre1: props.genre1,
        target: props.target,
        freshness: props.freshness,
        ai: props.ai
      },
      (res) => { fnc(res.data); }
    );
  }

  static getDailyCsv(props) {
    BaseApiModule.download(
      '/total/daily_csv',
      {
        total_col: props.totalCol,
        datekey_from: props.dateKeyFrom,
        datekey_end: props.dateKeyEnd,
        genre1: props.genre1,
        target: props.target,
        freshness: props.freshness,
        ai: props.ai
      },
      'daily.csv'
    );
  }

  static getMonthlyData(props, fnc) {
    BaseApiModule.post(
      '/total/monthly',
      {
        total_col: props.totalCol,
        genre1: props.genre1,
        target: props.target,
        freshness: props.freshness,
        ai: props.ai
      },
      (res) => { fnc(res.data); }
    );
  }

  static getMonthlyCsv(props) {
    BaseApiModule.download(
      '/total/monthly_csv',
      {
        total_col: props.totalCol,
        genre1: props.genre1,
        target: props.target,
        freshness: props.freshness,
        ai: props.ai
      },
      'monthly.csv'
    );
  }

  static getMonthlyDefaultOption() {
    return {
      totalCol: 'profit',
      genre1: 0,
      target: 0,
      freshness: 0,
      ai: 0
    };
  }

  static getYearlyData(props, fnc) {
    BaseApiModule.post(
      '/total/yearly',
      {
        total_col: props.totalCol,
        genre1: props.genre1,
        target: props.target,
        freshness: props.freshness,
        ai: props.ai
      },
      (res) => { fnc(res.data); }
    );
  }

  static getYearlyCsv(props) {
    BaseApiModule.download(
      '/total/yearly_csv',
      {
        total_col: props.totalCol,
        genre1: props.genre1,
        target: props.target,
        freshness: props.freshness,
        ai: props.ai
      },
      'yearly.csv'
    );
  }

  static getYearlyDefaultOption() {
    return {
      totalCol: 'profit',
      genre1: 0,
      target: 0,
      freshness: 0,
      ai: 0
    };
  }

  static getReport(fnc) {
    BaseApiModule.post(
      '/total/report',
      {},
      (res) => { fnc(res.data); }
    );
  }

  static saveBatch(fnc) {
    BaseApiModule.post('/batch/');
  }
}
