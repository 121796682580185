import axios from "axios";
import UserModule from "./UserModule";


export default class BaseApiModule {
  static post(path, params, fnc, err_fnc) {
    if (!fnc) fnc = () => {};
    if (!err_fnc) err_fnc = () => {};
    const url = process.env.REACT_APP_BASE_URL;
    let heads = {
      'Content-Type': 'application/json;charset=UTF-8'
    };
    const sessionId = UserModule.getSessionId();
    if (sessionId) {
      heads.Authorization = sessionId;
    }
    axios.post(
      url + path,
      params,
      { headers: heads }
    )
      .then(fnc)
      .catch(err_fnc);
  }

  static download(path, params, filename) {
    this.post(path, params, (res) => {
      const bl = new Blob([ res.data.csv ], { type: "text/csv" });

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(bl, filename);
        window.navigator.msSaveOrOpenBlob(bl, filename);
      } else {
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(bl);
        a.click();
      }
    });
  }
}
